import {
    Button,
    Datagrid,
    Edit,
    EditProps,
    EmailField,
    Identifier,
    Link,
    ListContextProvider,
    ListView,
    NumberInput,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useListContext,
    useListController,
    useRecordContext
} from 'react-admin';
import React, {Fragment,} from "react";
import {RESOURCE_CANDIDATES,} from "../../provider/restProvider";

import {FieldProps} from "ra-ui-materialui/src/field/types";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';

import AddToContactFavoriteButton from "../Candidate/AddToContactFavoriteButton";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import SendNotificationButton from "../Job/SendNotificationButton";
import AddProspectNotifyWithJobSelectButton from "../Job/AddProspectNotifyWithJobSelectButton";
import {candidateRowStyle} from "../Candidate/CandidateCommon";
import {commonStyles} from "../CommonStyles";
import LinkField from '../LinkField';
import {useFormContext, useWatch} from "react-hook-form";

const EditToolbar = (props) =>
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            variant="text"
        />
    </Toolbar>;


export interface FavouriteListEditProps extends EditProps {
  syncWithLocation: undefined | boolean;
}

const CandidateLinkField: React.FC<FieldProps> = () => {
    const record = useRecordContext();
    return (<Link to={`/${RESOURCE_CANDIDATES}/${record?.id}`}>
            <TextField source="firstName" sortable={false}/>
        </Link>
    );
};

interface RemoveButtonProps extends FieldProps {
  onRemove: (id: Identifier) => void
}

const RemoveButton: React.FC<RemoveButtonProps> = ({onRemove}) => {
    const record = useRecordContext();
    return <Button onClick={() => {
        if (record?.id) onRemove(record?.id);
    }}>
        <RemoveCircleIcon fontSize={'small'}/>
    </Button>;
}

export type BulkActionButtonsProps = {
    selectedIds: Identifier[];
};

const BulkActionButtons: React.FC = () => {
  const { selectedIds } = useListContext();

  return (
    <Fragment>
        <AddToContactFavoriteButton selectedIds={selectedIds} />
        <SendNotificationButton selectedIds={selectedIds} />
        <AddProspectNotifyWithJobSelectButton selectedIds={selectedIds} />
    </Fragment>
  );
};

const CandidatesList = () => {

    const candidateIds = useWatch({name: 'candidateIds', defaultValue: []});
    const {setValue} = useFormContext();

    const controllerProps = useListController({
        resource: RESOURCE_CANDIDATES,
        filter: {ids: candidateIds.length > 0 ? candidateIds : [0], searchTerm: ''},
        perPage: MAX_RECORD_PER_PAGE,
        sort: {field: "firstName", order: "ASC"},
    });

    return (<ListContextProvider value={controllerProps}>
        <ListView
            title={' '}
            empty={<Box margin={'20px'}> No candidate found. Select candidate(s) fom <Link to="/candidates">Candidates
                page</Link> and, Click "Add To Favorites List" to add a list</Box>}
        >
            <Datagrid
                rowClick={false}
                optimized={true}
                rowSx={candidateRowStyle}
                bulkActionButtons={<BulkActionButtons/>}
            >
                <CandidateLinkField source="firstName"/>
                <TextField source="lastName" sortable={false}/>
                <EmailField source="personalEmail" sortable={false}/>
                <EmailField source="workEmail" sortable={false}/>
                <LinkField phone source="homePhone"><TextField source="homePhone" label="Home Phone" sortable={false}/></LinkField>
                <LinkField phone source="mobile"><TextField source="mobile" sortable={false}/></LinkField>
                <TextField source="availabilityDate" label={'Availability'} sortable={false}/>
                <TextField source="availabilityDateLastUpdated" label={'Avail Last Updated'} sortable={false}/>
                <RemoveButton onRemove={(id) => {
                    setValue('candidateIds', candidateIds.filter(itemId => itemId !== id));
                }}/>
            </Datagrid>
    </ListView>
  </ListContextProvider>);
}

export const FavouriteListEdit: React.FC<FavouriteListEditProps> = ({syncWithLocation, ...props}) => {

  return (<Edit {...props} title={syncWithLocation === undefined ? '' : ' '}
                actions={false}
                mutationMode="pessimistic"
      >
          <SimpleForm toolbar={<EditToolbar/>} warnWhenUnsavedChanges>
              <Box sx={{display: 'block'}}>
                  <NumberInput source="id" label={'ID'} sx={commonStyles.formComponentDefaultMargins} disabled/>
                  <TextInput source="name" validate={[required()]} sx={commonStyles.formComponentDefaultMargins}/>
                  <TextInput source="fieldManagerCreatedName" label={'Created by'} disabled={true}
                             sx={commonStyles.formComponentDefaultMargins}/>
                  <TextInput source="fieldManagerUpdatedName" label={'Updated by'} disabled={true}
                             sx={commonStyles.formComponentDefaultMargins}/>
                  <TextInput
                      source="creationDate"
                      sx={commonStyles.formComponentDefaultMargins}
                      disabled={true}
                  />
                  <TextInput
                      source="lastModifiedDate"
                      sx={commonStyles.formComponentDefaultMargins}
                      disabled={true}
                  />
                  <CandidatesList/>

              </Box>

          </SimpleForm>
      </Edit>
  );
}
