import {
  ArrayField,
  ChipField,
  Datagrid,
  DeleteWithConfirmButton,
  EmailField,
  List,
  ListProps,
  SingleFieldList,
  TextField,
} from "react-admin";
import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import {PostPagination} from "../PostPagination";

export const UserList: React.FC<ListProps> = props => {
  return (
    <List title="Users"
          {...props}
          sort={{field: 'FirstName', order: 'ASC'}}
          exporter={false}
          perPage={100}
          pagination={<PostPagination/>}
          filterDefaultValues={{fieldManagersOnly: true}}
    >
      <Datagrid
          rowClick={false}
          optimized={true}
          bulkActionButtons={false}
      >
        <TextField source="title" sortable={false}/>
        <TextField source="firstName" sortable={true}/>
        <TextField source="lastName" sortable={true}/>
        <EmailField source="email" sortable={false}/>
        <ArrayField
            label={'Roles'}
            source={'roles'}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name"/>
          </SingleFieldList>
        </ArrayField>

        <TextField source="fieldManagerRecord.phoneNumber" label={'Phone Number'} sortable={false}/>
        <DeleteWithConfirmButton
            redirect={false}
            label={""}
            confirmTitle={'Archive Field Manager'}
            confirmContent={'Are you sure you want to archive this item?'}
            icon={<ArchiveIcon/>}/>

      </Datagrid>
    </List>
  );
};
