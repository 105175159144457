import React from "react";
import {lightTheme} from "../../theme";
import Box from "@mui/material/Box";

const classes = {
    container: {
        display: "flex",
        alignItems: "center"
    },
    border: {
        borderBottom: "1px solid lightgray",
        width: "100%"
    },
    content: {
        paddingTop: lightTheme.spacing(0.5),
        paddingBottom: lightTheme.spacing(0.5),
        paddingRight: lightTheme.spacing(2),
        paddingLeft: lightTheme.spacing(2),
        fontWeight: 400,
        fontSize: 13,
        color: "gray !important",
        whiteSpace: 'nowrap'
    }
};


const DividerWithText = ({children}) => {
    return (
        <Box sx={classes.container}>
            <Box sx={classes.border}/>
            <Box sx={classes.content}>{children}</Box>
            <Box sx={classes.border}/>
        </Box>
    );
};
export default DividerWithText;
