import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  email,
  minLength,
  required,
  SimpleForm,
  TextInput
} from 'react-admin';
import React from "react";


const confirmPasswordValidation = (value, allValues) => {
  if (value !== allValues['password']) {
    return 'Passwords are not same!';
  }
  return undefined;
};

export const rolesChoices = [
  {
    "id": 3,
    "name": "FieldManager"
  },
  {
    "id": 1,
    "name": "Admin"
  }
];

export const transformRoles = (item) => {
  if (Array.isArray(item.roles)) {
    const roles = item.roles.map(roleId => {
      if (typeof roleId === 'number') {
        return rolesChoices.find(r => r.id === roleId)
      } else {
        return roleId;
      }
    });
    return {...item, roles};
  } else {
    return item;
  }
};


export const UserCreate: React.FC<CreateProps> = (props) => {

  return (<Create {...props} actions={false} transform={(data) => {
        return {
          ...transformRoles(data),
          fieldManagerRecord: {
            email: data.email,
            name: `${data.firstName} ${data.lastName}`
          },
          acceptTerms: true
        }
    }}>
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="title" validate={[required()]}/>
          <TextInput source="firstName" validate={[required()]}/>
          <TextInput source="lastName" validate={[required()]}/>
          <TextInput source="email" type="email" validate={[required(), email()]}/>
          <TextInput source="phoneNumber" label={'Phone Number'}/>
          <AutocompleteArrayInput
              variant={'outlined'}
              source="roles"
              choices={rolesChoices}
              multiple
          />

          <TextInput source="password" type="password" validate={[required(), minLength(6)]}/>
          <TextInput source="confirmPassword" type="password" validate={[required(), confirmPasswordValidation]}/>
        </SimpleForm>

    </Create>
  );

}

