import {truncateLongMessage} from "../../provider/UtilityFunctions";


export function genericApiErrorHandler(toastTitle, ERROR_ACTION_TYPE, err, dispatch, notify) {
    console.error(err);
    if (err.response) {
        const status = err.response.status;
        if (status === 401) {
            if (notify) notify("Please logout and login again!", {type: "warning"});
        } else {
            let message = err.message || '';
            if (err.response.data && err.response.data.message) {
                message = err.response.data.message;
            }
            console.error(message);
            if (ERROR_ACTION_TYPE) dispatch({type: ERROR_ACTION_TYPE, error: err.message});
            if (toastTitle) {
                if (notify) notify(toastTitle + '\n' + truncateLongMessage(message, 100), {
                    type: "warning",
                    multiLine: true
                });
            }
        }
    } else {
        if (ERROR_ACTION_TYPE) {
            dispatch({type: ERROR_ACTION_TYPE, error: err.message});
        }
    }
}
