import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button, Identifier, useNotify, useUnselectAll } from 'react-admin';
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import api from "../../provider/api";
import ClientContactSelectionDialog from '../ClientContacts/ClientContactSelectionDialog';

export type AddToContactFavoriteButtonProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
}

const AddToContactFavoriteButton: React.FC<AddToContactFavoriteButtonProps> = ({ selectedIds = [], onSuccess }) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATES);
  const [isOpen, setIsOpen] = useState(false);

  const onClicked = () => {
    setIsOpen(true);
  }

  return <>
    <Button
      label="Add to Contact Favorite"
      onClick={onClicked}
      disabled={selectedIds.length === 0}
    >
      <FavoriteBorderIcon/>
    </Button>

    {isOpen && <ClientContactSelectionDialog
      onClose={() => setIsOpen(false)}
      onSelect={(record) => {
        api.clientContacts.favoriteCandidates(record.id, selectedIds)
          .then(() => {
            notify('Added to Favorite list', {type: 'info', undoable: false});
            unselectAll();
            if (onSuccess) onSuccess();
          })
          .catch((reason) => {
            console.log("Reason", reason);
            notify('Try again, please', {type: 'warning', undoable: false});
          });
        setIsOpen(false);
      }}
    />}
  </>;
};

AddToContactFavoriteButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AddToContactFavoriteButton;
