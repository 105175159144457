import {
    CreateButton,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    EditButton,
    EmailField,
    Filter,
    FilterProps,
    Identifier,
    List,
    ListProps,
    NumberField,
    TextField,
    TopToolbar,
    useListContext,
    useRecordContext,
    useStore
} from "react-admin";
import CustomDateInput from "../CustomDateInput";
import {BLACK, GREEN, GREY, PURPLE, RED, YELLOW} from "../../theme/colors";
import React, {Fragment} from 'react';
import AddToBasketButton from "./AddToBasketButton";
import {CandidateEdit} from "./CandidateEdit";
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import moment from "moment";
import {DEFAULT_DATE_FORMAT, PROSPECT_CANDIDATE_SUBMITTED} from "../../provider/constants";
import ActionsColumn from "../ActionsColumn";
import CandidateBasketButton from "./CandidateBasketButton";
import AddToContactFavoriteButton from "./AddToContactFavoriteButton";
import AvatarColumnField from "../AvatarColumnField";
import LegendButton from "../LegendButton";
import CustomBooleanField from "../CustomBooleanField";
import ExportButton from "../ExportButton";
import AddToFavoriteListButton from "./FavouriteList/AddToFavoriteListButton";
import SendNotificationButton from "../Job/SendNotificationButton";
import AddProspectNotifyWithJobSelectButton from "../Job/AddProspectNotifyWithJobSelectButton";
import DeleteMultipleButton from "./DeleteMultipleButton";
import FreeTextSearchInput from "../FreeTextSearchInput";
import {commonStyles} from "../CommonStyles";
import {PostPagination} from "../PostPagination";
import LinkField from "../LinkField";
import {FilterSidebar} from "../Filters/FilterSidebar";
import {FormControlLabel, Switch} from '@mui/material';
import SelectColumnsButtonAutoSave from "../SelectColumnsButtonAutoSave";

// const defaultColumns: string[] = [
//     'avatarURL',
//     'ID',
//     'firstName',
//     'lastName',
//     'personalEmail',
//     'availabilityDate',
//     'city'
// ];


const CandidateActions = () => (
    <TopToolbar>
        <FilterSidebarButton/>
        <CandidateFilter context={'button'}/>
        <CreateButton/>
        <CandidateBasketButton/>
        <LegendButton legends={[
            {
                label: 'Do not hire',
                color: BLACK
            },
            {
                label: 'Submitted to Job(s)',
                color: PURPLE
            },
            {
                label: 'Not available',
                color: RED
            },
            {
                label: 'Available within 5 months',
                color: YELLOW
            },
            {
                label: 'Available [Last month - Next 2 months]',
                color: GREEN
            },
            {
                label: 'No Availability Date',
                color: GREY
            },
        ]}/>
        <ExportButton/>
        <SelectColumnsButtonAutoSave/>
    </TopToolbar>
);

const FilterSidebarButton = () => {
    const {filterValues, setFilters, displayedFilters} = useListContext();
    const [sideFilters, setSideFilters] = useStore('candidates.sideFilters', false);

    return <FormControlLabel
        control={<Switch size='small' defaultChecked={sideFilters}
                         onChange={e => {
                             setFilters({
                                 ...filterValues,
                                 "sideFilters": []
                             }, {
                                 ...displayedFilters,
                                 "sideFilters": e.target.checked
                             });
                             setSideFilters(e.target.checked);
                         }}
        />}
        label="Show Filters"
    />;
}

const CandidateFilter: React.FC<Omit<FilterProps, 'children'>> = props => {

    return (
        <Filter {...props} variant={'outlined'}>
            <FreeTextSearchInput source="searchTerm" alwaysOn label={"Free Text Search"}/>

            <CustomDateInput
                source="availabilityStart"
                label="Avail Start"
      />
      <CustomDateInput
        source="availabilityEnd"
        label="Avail End"
      />
    </Filter>
  );
};

const CandidateDetail = () => {
  const record = useRecordContext();

  return <CandidateEdit
    resource={RESOURCE_CANDIDATES} id={record?.id}
    syncWithLocation={false}
  />;
};


const getRowBorderColor = (record) => {

  const jobResponses = Array.isArray(record['jobResponses']) ? record['jobResponses'] : [];
    const submittedJob = jobResponses.find(jr => jr.prospectStatusId === PROSPECT_CANDIDATE_SUBMITTED);

  if (record.doNotHire) {
    return BLACK;
  } else if(submittedJob){
    return PURPLE;
  } else if (record['availabilityDate']) {
    const availMoment = moment(record['availabilityDate'], DEFAULT_DATE_FORMAT);
      const nowMoment = moment();
      const days = availMoment.diff(nowMoment, 'days');

      if (days < -30) {
          return RED;
      } else if (days < 2 * 30) {
          return GREEN;
      } else if (days < 5 * 30) {
          return YELLOW;
      } else {
          return RED;
      }
  }
  return GREY;
};

export const candidateRowStyle = (record) => {
  let style = {};
  if (!record) {
    return style;
  }
  return {
    ...style,
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export type CandidateBulkActionButtonsProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
}

const CandidateBulkActionButtons: React.FC = () => {
  const { selectedIds } = useListContext();

  return (
    <>
      <DeleteMultipleButton selectedIds={selectedIds} />
      <AddToContactFavoriteButton selectedIds={selectedIds} />
      <AddToBasketButton selectedIds={selectedIds} />
      <AddToFavoriteListButton selectedIds={selectedIds} />
      <SendNotificationButton selectedIds={selectedIds} />
      <AddProspectNotifyWithJobSelectButton selectedIds={selectedIds} />
    </>
)};

export const CandidateList: React.FC<ListProps> = props => {
    return (
        <List title="Candidates"
              {...props}
              sort={{field: 'firstName', order: 'ASC'}}
              filters={<CandidateFilter/>}
              filter={{includeFields: ["jobResponses", "address"]}}
              actions={<CandidateActions/>}
              perPage={100}
              pagination={<PostPagination/>}
              aside={<FilterSidebar availableFilters={[
                  {displayName: 'Skills', apiName: 'skill'},
                  {displayName: 'Clearances', apiName: 'clearance'},
                  {displayName: 'Client Experiences', apiName: 'clientexperience'},
                  {displayName: 'OEM Experience Types', apiName: 'oemexperiencetype'},
                  {displayName: 'High Level Industries', apiName: 'highlevelindustry'},
                  {displayName: 'Subindustres', apiName: 'subindustry'},
                  {displayName: 'Work Experience Levels', apiName: 'workexperiencelevel'},
                  {
                      displayName: 'Work Scope Experience',
                      apiName: 'primaryworkscopeexperience',
                      subFilter: {displayName: 'Job Title', apiName: 'jobtitle'}
                  },
              ]}/>}
        >

            <DatagridConfigurable
                bulkActionButtons={<CandidateBulkActionButtons/>}
                rowClick="expand"
                // optimized={true}
                expand={<CandidateDetail/>}
                rowSx={candidateRowStyle}
                sx={commonStyles.defaultGridStyle}
            >


                <AvatarColumnField source="avatarURL" size={25} label="Avatar" sortable={false}/>
                <NumberField source="id" label={'Candidate ID'} sortable={true}/>
                <TextField source="firstName" sortable={true}/>
                <TextField source="lastName" sortable={true}/>
                <TextField source="nickName" sortable={true}/>
                <EmailField source="workEmail" sortable={true}/>
                <EmailField source="personalEmail" sortable={true}/>
                <LinkField phone source="homePhone" label="Home Phone"><TextField source="homePhone" label="Home Phone"
                                                                                  sortable={true}/></LinkField>
                <LinkField phone source="mobile"><TextField source="mobile" sortable={true}/></LinkField>
                <LinkField phone source="otherPhone">
                    <TextField source="otherPhone" sortable={true}/>
                </LinkField>
                <TextField source="birthday" sortable={true}/>
                <TextField source="referredBy" sortable={true}/>
                <TextField source="availabilityDate" label={'Availability'}
                           sortable={true}/>
                <CustomBooleanField label="Do Not Hire" source="doNotHire" sortable={true}/>
                <CustomBooleanField label="Military Exp"
                                    source="hasMilitaryExperience" sortable={true}/>
                <TextField source="createdByName" label="Created By" sortable={false}/>
                <TextField source="updatedByName" label="Updated By" sortable={false}/>
                <CustomBooleanField label="Comp. Wizard" source="completedWizard"
                                    sortable={true}/>
                <TextField source="creationDate" label={'Creation'} sortable={true}/>,
                <TextField source="lastModifiedDate" label={'Last Modified'}
                           sortable={true}/>
                <TextField source="lastLoginOnMobileAppDate"
                           label={'Last login on Mobile'}
                           sortable={true}/>
                <TextField source="availabilityDateLastUpdated"
                           label={'Avail Last Updated'}
                           sortable={true}/>
                <TextField source="addresses[0].address" label={"Address"} sortable={false}/>
                <TextField source="addresses[0].unitNumber" label={"Unit"}
                           sortable={false}/>
                <TextField source="addresses[0].city" label={"City"} sortable={false}/>
                <TextField source="addresses[0].state" label={"State"} sortable={false}/>
                <TextField source="addresses[0].country" label={"Country"} sortable={false}/>
                <TextField source="addresses[0].zip" label={"Zip"} sortable={false}/>


                <ActionsColumn label={'Actions'}>
                    <EditButton label=""/>
                    <DeleteWithConfirmButton label=""/>
                </ActionsColumn>
            </DatagridConfigurable>

    </List>
  );
};
