import { Login, LoginForm } from "react-admin";
import { Link } from "react-router-dom";
import Logo from "../assets/LeftLogo.png";
import { Box } from "@mui/material";

const HRLogin = () => {
    return (
        <Login title="Harmoniq HR Portal" backgroundImage={Logo}>
            <Box sx={{ '.MuiTextField-root': { width: '100%' } }}>
                <LoginForm />
                <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
                    <Link to="/enter-email">Forgot password ?</Link>
                </p>
            </Box>
        </Login>
    );
};

export default HRLogin;
