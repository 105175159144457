import React, {useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {filterType, sideFiltersType} from "./types";
import {AutocompleteArrayInput, AutocompleteInput, useGetList, useListContext,} from "react-admin";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import {useFormContext} from 'react-hook-form';

/**
 * Determines whether to render an autocomplete allowing
 * multiple select or not (as they are separate components in RA)
 * @param multiple - Whether the user may select mutliple values
 * @param ...rest - Any props to be passed to the autocomplete
 */
const FilterAutocomplete = ({ multiple, ...rest }) => {
  if (multiple) {
    return <AutocompleteArrayInput {...rest} />;
  } else {
    return <AutocompleteInput {...rest} clearOnBlur clearOnEscape />;
  }
};

/**
 * Renders autocomplete for given filter
 * @param filter {object} - the filter object
 * @param subFilterData {any} - Any data needed if currently a subfilter
 *
 */
const SidebarFilter = ({
  filter,
  subFilterData,
}: {
  filter: filterType;
  subFilterData?: any;
}) => {
  const {setValue}= useFormContext()
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const [subFilterKeyword, setSubFilterKeyword] = useState();
  const { data: currentFilterOptions, refetch } = useGetList(`lookup/${filter.apiName}`,
    { pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE }, filter: { keyword: subFilterData || undefined } },
    { enabled: false }
  );
  // Calls getFilterData each render IF we have changed our subFilterData
  // i.e the criteria for subFiltering
  useEffect(() => {
    if (subFilterData) {
      refetch();
    }
    // Set filter inputs to match the filter url
    setValue(filter.apiName, filterValues?.sideFilters?.find(item => item.name === filter.apiName)?.ids || null)
  }, [filter.apiName, filterValues?.sideFilters, refetch, setValue, subFilterData]);
  const onFilterSelect = (values) => {
    // Special case for job Title
    if (filter.apiName === "jobtitle") {
      // Get the text values of IDs passed
      let textValues = values.map((value) => {
        const optionObjects = currentFilterOptions.filter((filterOption) => {
          return filterOption.id === value;
        });
        return optionObjects.map((option) => {
          return option.name;
        });
      });
      // Escape values
      textValues = textValues.map(word => `"${word}"`);
      // Format them into a search string
      const newFilters = {
        ...filterValues,
        jobTitleSearchTerm: textValues.join(" AND "),
      };
      setFilters(newFilters, displayedFilters);
      return;
    }
    // When coming from a filter with a sub filter, we won't recieve an array
    if (!(values instanceof Array)) {
      const subFilterKeywordValue = currentFilterOptions.find((item) => {
        return item.id === values;
      });
      setSubFilterKeyword(subFilterKeywordValue?.name || null);
    }
    // Ensure sideFilters exists
    const newFilters: { sideFilters: sideFiltersType } = {
      ...filterValues,
      sideFilters: filterValues.sideFilters || [],
    };

    //Remove existing filter, first
    newFilters.sideFilters = newFilters.sideFilters.filter(
      (item) => item.name !== filter.apiName
    );
    if (Array.isArray(values) && values.length > 0) {
      //Assign New filter
      newFilters.sideFilters.push({
        ids: values,
        name: filter.apiName,
      });
    }

    setFilters(newFilters, displayedFilters);
  };
  return (
    <>
      <Accordion
        onChange={() => {
          // Get all data from lookup WHEN OPENING SPECIFIC FILTER
          if (!currentFilterOptions) {
            refetch();
          }
        }}
        sx={{
          width: "100%",
          m: "0!important",
          boxShadow: "none",
          ":before": { opacity: "1!important" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${filter.displayName}-content`}
          id={`${filter.displayName}-header`}
          sx={{
            padding: 0,
            minHeight: ".625rem",
            "> div": { margin: "0!important" },
          }}
        >
          <p>{filter.displayName}</p>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <FilterAutocomplete
              multiple={!filter.subFilter}
              source={filter.apiName}
              label={currentFilterOptions ? "Select Item" : "Loading..."}
              disabled={!currentFilterOptions}
              choices={currentFilterOptions || []}
            // For some reason this line is needed to prevent all options disappearing. DO NOT REMOVE EVEN THOUGH IT LOOKS USELESS
            // UPDATE: This is a React admin bug.
              setFilter={(searchText) => searchText}
              sx={{ mt: 0, width: "100%" }}
              onChange={onFilterSelect}
          />
        </AccordionDetails>
      </Accordion>

      {
        // Recursively render subfilter
        filter.subFilter && subFilterKeyword ? (
          <SidebarFilter
            filter={filter.subFilter}
            subFilterData={subFilterKeyword}
          />
        ) : null
      }
    </>
  );
};

export default SidebarFilter;
