import {
    BooleanInput,
    Create,
    CreateProps,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectArrayInput,
    SimpleForm,
    Toolbar,
    useRecordContext,
    useRefresh,
} from "react-admin";
import {RichTextInput,} from 'ra-input-rich-text';
import {getUser} from "../provider/authProvider";
import {RESOURCE_LOOKUP_FIELDMANAGER,} from "../provider/restProvider";
import CommentIcon from '@mui/icons-material/Comment';
import Box from "@mui/material/Box";
import {useFormContext} from 'react-hook-form';

const user = getUser();

const Actions = ({associatedEntityId, ...rest}) => {
    const form = useFormContext();
    const refresh = useRefresh();

    return (
        <Toolbar {...rest}>
            <SaveButton
                icon={<CommentIcon/>}
                label="Add Comment"
                variant="text"
                type="button"
                transform={(data) => {
                    return {
                        ...data,
                        fieldManagerId: user.fieldManagerId,
                        associatedEntityId,
                        fieldManagerIds: data.fieldManagerIds || [],
                    };
                }}
                mutationOptions={{
                    onSuccess: () => {
                        form.reset();
                        refresh();
                    },
                }}
            />
        </Toolbar>
    );
};

export interface CommentCreateProps extends CreateProps {
    resource: string
}

// @ts-ignore
export const CommentCreate: React.FC<CommentCreateProps> = ({resource}) => {
    const record = useRecordContext();

    return (
        <Create
            title={" "}
            resource={resource}
            actions={false}
        >
            <SimpleForm toolbar={<Actions associatedEntityId={record?.id}/>}>
                <RichTextInput
                    source="text"
                    label={false}
                    fullWidth={true}
                    validate={[required()]}
                />

                <Box sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                }}>

                    <ReferenceArrayInput
                        reference={RESOURCE_LOOKUP_FIELDMANAGER}
                        source={"fieldManagerIds"}
                        label={"Notify field managers"}
                    >
                        <SelectArrayInput
                            // allowEmpty={true}
                        />
                    </ReferenceArrayInput>

                    <BooleanInput
                        source="sendToAll"
                        label="Notify all field managers"
                        defaultValue={false}
                        sx={{marginLeft: '10px'}}
                    />

                </Box>

            </SimpleForm>
        </Create>
    );
};
