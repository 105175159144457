import types from "./types";

export function clearBasket(resource) {
    return {type: types.basket.BASKET_CLEAR, resource}
}

export function addToBasket(resource, data) {
    return {type: types.basket.BASKET_ADD_ITEMS, resource, data}
}


export function removeFromBasket(resource, ids) {
    return {type: types.basket.BASKET_REMOVE_ITEMS, resource, ids}
}
