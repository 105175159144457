import {AxiosResponse} from "axios";
import api from "./api";
import {isNotEmpty} from "./UtilityFunctions";

export const ID_SEPARATOR = "|";

export function sanitizeEmptyFields(data: object, _fieldList: string[] = []) {
    const retVal = {...data};
    const fieldList = _fieldList.length > 0 ? _fieldList : Object.keys(data);
    fieldList.forEach(field => {
        if (typeof retVal[field] === 'string' && retVal[field] === '') {
            retVal[field] = null;
        }
    });
    return retVal;
}

export function encodeId(secondaryKey, data) {
    let transformedData;
    const encodeObject = (obj) => {
        const {id, ...rest} = obj;
        return {
            id: `${id}${ID_SEPARATOR}${obj[secondaryKey]}`,
            ...rest
        }
    };
    if (Array.isArray(data)) {
        //Update id field with "id|SecondaryKey"
        transformedData = data.map(item => encodeObject(item));
    } else if (typeof data === 'object') {
        transformedData = encodeObject(data);
    } else {
        throw "Unsupported encode input";
    }
    return transformedData;
}

export function decodeId(secondaryKey, data) {
    let transformedData;
    const decodeObject = (obj) => {
        const {id, ...rest} = obj;

        if (typeof id === "string") {
            const parts = id.split(ID_SEPARATOR);
            try {
                // @ts-ignore
                parts[0] = parseInt(parts[0]);
            } catch (e) {
            }

            try {
                if (parts.length > 1) { // @ts-ignore
                    parts[1] = parseInt(parts[1]);
                }
            } catch (e) {
            }

            if (parts.length > 1) {
                return {
                    id: parts[0],
                    [secondaryKey]: parts[1],
                    ...rest
                }
            } else {
                return {
                    id: parts[0],
                    ...rest
                }
            }
        }

        return obj;
    };

    if (Array.isArray(data)) {
        //Update id field by parsing "id|SecondaryKey"
        transformedData = data.map(item => decodeObject(item));
    } else if (typeof data === 'object') {
        transformedData = decodeObject(data);
    } else {
        throw "Unsupported decode input";
    }
    return transformedData;
}

export async function getDocuments(apiFn, Id) {
    try {
        const response: AxiosResponse = await apiFn(Id);
        if (response.status === 200) {
            const {data} = response;

            if (data && Array.isArray(data.model)) {
                const retVal = [];
                for (let i = 0; i < data.model.length; i++) {
                    const url = await getDocumentUrl(data.model[i].id);
                    retVal.push({...data.model[i], url});
                }
                // @ts-ignore
                const retValSorted = retVal.sort((a, b) => a.documentType < b.documentType);
                return Promise.resolve({data: retValSorted, total: retValSorted.length})
            }
        }
        return Promise.reject(`Couldn't get candidate documents(${Id})`);

    } catch (err) {
        return Promise.reject(err);
    }
}

export async function getDocumentUrl(documentId) {
    try {
        const response = await api.documents.getUrl(documentId);
        const {data} = response;
        if (isNotEmpty(data) && data.includes('https')) {
            return data;
        }
    } catch (err) {
        console.error(err);
    }
    return undefined;
}
