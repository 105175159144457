import React, {useState} from "react";
import {isEmpty, isNotEmpty} from "../../provider/UtilityFunctions";
import {useChatMessage} from "./ChatMessageProvider";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';

export interface MessageSendProps {
    isSendDisabled: boolean,
    candidateId: number
}

const MessageSend: React.FC<MessageSendProps> = ({candidateId, isSendDisabled}) => {
    const {sendMessage} = useChatMessage();

    const [messageTxt, setMessageTxt] = useState("");
    const [sendingState, setSendingState] = useState({loading: false, loaded: false, error: false});

    const onMessageSend = () => {
        setSendingState({loading: true, loaded: false, error: false});
        sendMessage(candidateId, messageTxt)
            .then(() => {
                setSendingState({loading: false, loaded: true, error: false});
                setMessageTxt('');
            })
            .catch((err) => {
                console.log("Message send failed!", err);
                setSendingState({loading: false, loaded: false, error: true});
            });
    };

    const onKeyUp = (event) => {
        if (event.key == "Enter" && isNotEmpty(event.target.value)) {
            onMessageSend();
        }
    };

    return (
        <div className="MessageContainerSend">
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                    <TextField margin={'dense'}
                               variant="outlined"
                               fullWidth={true}
                               value={messageTxt}
                               onChange={(e) => setMessageTxt(e.target.value)}
                               placeholder='Write a message ...'
                               disabled={isSendDisabled || sendingState.loading}
                               error={sendingState.error}
                               className='MessageContainerSendInput'
                               onKeyUp={onKeyUp}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        disabled={sendingState.loading || isSendDisabled || isEmpty(messageTxt)}
                        onClick={onMessageSend}
                        endIcon={<SendIcon/>}
                        size="large"
                        // loading={sendingState.loading}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>
        </div>);
};

export default MessageSend;
