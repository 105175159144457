import React, {FC, useState} from "react";
import {InputProps, useInput} from 'ra-core';
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch, {SwitchProps} from '@mui/material/Switch';
import {SxProps} from "@mui/material";

export interface ReverseBooleanInputProps extends InputProps<SwitchProps> {
    sx?: SxProps
}

const ReverseBooleanInput: FC<ReverseBooleanInputProps> = ({source, sx, label, ...rest}) => {
    const {field,} = useInput({source});
    const [reversedValue, setReversedValue] = useState(Boolean(!Boolean(field.value)));

    return <FormControlLabel
        control={
            <Switch
                //ToDo: Check disabled ??
                // disabled={disabled}
                checked={reversedValue}
                onChange={(event) => {
                    setReversedValue(event.currentTarget.checked);
                    field.onChange(!event.currentTarget.checked);
                }}
                color="primary"
            />
        }
        label={label}
        sx={sx}
    />;
}


export default ReverseBooleanInput;
