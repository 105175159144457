import {DialogContent} from '@mui/material';
import {
    Button,
    Datagrid,
    EmailField,
    Filter,
    FilterProps,
    List,
    ReferenceField,
    TextField,
    TextInput,
    useRecordContext
} from "react-admin";
import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import React from "react";
import {RESOURCE_CLIENT_CONTACTS, RESOURCE_CLIENTS} from "../../provider/restProvider";
import {FieldProps} from "ra-ui-materialui/src/field/types";
import Box from "@mui/material/Box";
import { PostPagination } from '../PostPagination';

export interface ClientContactSelectionDialogProps {
    onClose: () => void
    onSelect: (record: any) => void
}

interface SelectButtonProps extends FieldProps {
  onSelect: (record: any) => void
}

const SelectButton: React.FC<SelectButtonProps> = ({onSelect}) => {
    const record = useRecordContext();
    return <Button onClick={() => onSelect(record)} label={'Select'}/>;
}

const classes = {
    filter: {
        "& form": {
            minHeight: 70
        }
    },
};

const ClientFilter: React.FC<Omit<FilterProps, 'children'>> = props => {

  return (
      <Box sx={classes.filter}>
          <Filter {...props} >
              <TextInput source="searchTerm" label={'Search'} alwaysOn/>
          </Filter>
      </Box>
  );
};

const ClientContactSelectionDialog: React.FC<ClientContactSelectionDialogProps> = ({onClose, onSelect}) => {

  return <Dialog maxWidth="lg" onClose={onClose} open>
    <DialogTitle>Select Contact</DialogTitle>
    <DialogContent>

      <List title=""
            resource={RESOURCE_CLIENT_CONTACTS}
            sort={{field: 'firstName', order: 'ASC'}}
            pagination={<PostPagination/>}
            filters={<ClientFilter/>}
            perPage={100}
            exporter={false}
      >
          <Datagrid rowClick={false} optimized={true} bulkActionButtons={false}>
              <ReferenceField label="Client" source={"clientId"} reference={RESOURCE_CLIENTS} link={false}
                              sortable={false}>
                  <TextField source="name"/>
              </ReferenceField>
              <TextField source={"firstName"} label={'First Name'} sortable={false}/>
              <TextField source={"lastName"} label={'Last Name'} sortable={false}/>
              <EmailField source={"email"} label={'Email'} sortable={false}/>
              <SelectButton onSelect={onSelect}/>
          </Datagrid>
      </List>

    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" label="Close">
        <IconClose/>
      </Button>
    </DialogActions>
  </Dialog>;
}

export default ClientContactSelectionDialog;
