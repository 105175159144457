import * as React from 'react'
import {useEffect, useState} from 'react'
import api from "../../provider/api";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";
import {loadChatRooms, receiveMessage} from "../../redux/actions/chatkitActions";
import {isEmpty, isNotEmpty} from "../../provider/UtilityFunctions";
import {useDispatch} from "react-redux";

export interface ChatContextType {
    isChatConnected: () => boolean,
    sendMessage: (candidateId: number, messageText: string) => Promise<any>,
    connect: () => void,
    disconnect: () => void
}

export const ChatContext = React.createContext<ChatContextType>({
    sendMessage: () => {
        throw "Not initialized"
    },
    isChatConnected: () => false,
    connect: () => {
        throw "Not initialized"
    },
    disconnect: () => {
        throw "Not Initialized"
    }
});

export function useChatMessage() {
    const context = React.useContext(ChatContext)
    if (context === undefined) {
        throw new Error('useChatMessage must be used within a ChatMessageProvider')
    }
    return context
}

export const ChatMessageProvider = ({children}) => {
    const dispatch = useDispatch();
    const [connection, setConnection] = useState<HubConnection | undefined>(undefined);
    const [isConnecting, setIsConnecting] = useState(false);

    useEffect(() => {
        if (isConnecting) {
            const createNewConnection = async () => {
                console.log("\n\nConnecting to Chat Server \n\n");

                try {
                    const _conn = new HubConnectionBuilder()
                        .withUrl(api.chat.getChatURL())
                        .withAutomaticReconnect()
                        .build();
                    setConnection(_conn);

                    // @ts-ignore
                    dispatch(loadChatRooms(undefined));

                    _conn.onclose((e: any) => {
                        connection.off('ReceiveMessage');
                        console.log('\n---Onclose-----');
                    });


                    await _conn.start();
                    _conn.on('ReceiveMessage', onMessageReceived);
                    setIsConnecting(false);

                } catch (err) {
                    console.log("Couldn't start Chat connection", err);
                    alert("Sorry, Couldn't connect to chat server");
                    setIsConnecting(false);
                }

            }

            if (!connection) {
                createNewConnection().then();
            } else if (connection && connection.state !== 'Connected') {
                connection.off('ReceiveMessage');
                connection.start()
                    .then(() => {
                        connection.on('ReceiveMessage', onMessageReceived);
                        setIsConnecting(false);
                    })
                    .catch((err) => {
                        console.log("ERROR on connection.start", err)
                    });
            }
        }
    }, [isConnecting]);

    const isChatConnected = () => {
        try {
            return Boolean(connection) && connection.state === 'Connected';
        } catch (err) {
            console.log("isChatConnected FAILED", err);
            return false;
        }
    }

    const onMessageReceived = (message) => {
        if ((isEmpty(message.user) || message.user.toLowerCase() !== 'system') && isNotEmpty(message.chatSessionId)) {
            // @ts-ignore
            dispatch(receiveMessage(message, undefined));
        } else {
            console.log("Message received", message);
        }
    }

    const sendMessage = (candidateId: number, messageText: string) => {
        if (isChatConnected()) {
            return connection.send('SendMessage', {candidateId, messageText});
        } else {
            return Promise.reject("Not connected!");
        }
    }

    const connect = () => {
        if (isConnecting) {
            console.log("ChatMessageProvider is already trying to connect!");
            return;
        }
        setIsConnecting(true);
    }

    const disconnect = () => {
        //It is failing sometimes.Error coming from SignalR
        try {
            if (isChatConnected()) {
                console.log("\n\nDisconnection from Chat Server \n\n");
                // @ts-ignore
                connection.off('ReceiveMessage');
                // @ts-ignore
                connection.stop().then().catch();
                setIsConnecting(false);
            }
        } catch (_) {

        }
    }

    return <ChatContext.Provider value={{
        connect,
        sendMessage,
        isChatConnected,
        disconnect
    }}>
        {children}
    </ChatContext.Provider>
};

