import {Button, downloadCSV, useGetList, useListContext} from "react-admin";
import {MAX_EXPORT_PER_PAGE} from "../provider/constants";
import React, {useEffect, useState} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";
import jsonExport from 'jsonexport/dist';

interface ExportProps {
  excludedColumns?: Array<string>
}

const Downloading = ({resource, sort, filters, excepts, onCompleted}) => {
  const {data, isLoading} = useGetList(resource, {
    pagination: {page: 1, perPage: MAX_EXPORT_PER_PAGE},
    sort,
    filter: filters
  });
  
  useEffect(() => {
    if (!isLoading && data) {
      const exportData = data.map(row => {
        const newRow = { ...row };
        
        if (excepts) {
          excepts.forEach(column => {
            delete newRow[column];
          });
        }

        return newRow;
      });

      jsonExport(Object.values(exportData), {
        // headers: ['id', 'post_id', 'post_title', 'body'],
      }, (err, csv) => {
        downloadCSV(csv, `${resource}-${moment().format('MMM-DD-YYYYTHHmm')}`);
        onCompleted();
      });

    }
  }, [isLoading, data]);

  return <CircularProgress size={'1rem'}/>;
}

const ExportButton: React.FC<ExportProps> = ({excludedColumns}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {resource, sort, filterValues, } = useListContext();

  return <Button
      label={'Export'}
      disabled={isDownloading}
      onClick={() => setIsDownloading(true)}>
    {isDownloading ?
        <Downloading
            resource={resource}
            sort={sort}
            filters={filterValues}
            excepts={excludedColumns}
            onCompleted={() => setIsDownloading(false)}
        /> : <CloudDownloadIcon/>}
  </Button>;

}

export default ExportButton;
