import DialogActions from '@mui/material/DialogActions';
import IconClose from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogContent} from '@mui/material';
import {
    Button,
    Datagrid,
    EmailField,
    Filter,
    FilterProps,
    Identifier,
    ListContextProvider,
    ListView,
    ReferenceField,
    TextField,
    TextInput,
    useGetList
} from "react-admin";
import React, {useState} from "react";
import {RESOURCE_CLIENT_CONTACTS, RESOURCE_CLIENTS} from "../../provider/restProvider";
import Box from "@mui/material/Box";
import {PostPagination} from '../PostPagination';
import {ListControllerResult} from "ra-core/dist/cjs/controller/list/useListController";

export interface MultipleClientContactSelectionDialogProps {
    onClose: () => void
    onSelect: (ids: Identifier[]) => void;
    filter?: object
}

const classes = {
    filter: {
        "& form": {
            minHeight: 70
        }
    },
};

const SearchFilter: React.FC<Omit<FilterProps, 'children'>> = props => {

  return (
      <Box sx={classes.filter}>
          <Filter {...props} >
              <TextInput source="searchTerm" label={'Search'} alwaysOn/>
          </Filter>
      </Box>
  );
};

export type BulkActionButtonsProps = {
    selectedIds: Identifier[];
    onSelect: (ids: Identifier[]) => void;
}
const BulkActionButtons : React.FC<BulkActionButtonsProps> = ({ selectedIds, onSelect }) => {
  return (
    <>
      <Button
        label="Assign to Project"
        onClick={() => onSelect(selectedIds)}
        disabled={selectedIds == undefined || selectedIds.length == 0}
      />
    </>
  );
};

const MultipleClientContactSelectionDialog: React.FC<MultipleClientContactSelectionDialogProps> = ({
                                                                                                       onClose,
                                                                                                       onSelect,
                                                                                                       filter: defaultFilter
                                                                                                   }) => {

    const [filter, setFilter] = useState(defaultFilter);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);

    const sort = {field: 'firstName', order: 'ASC'};
    const {data, total} = useGetList(RESOURCE_CLIENT_CONTACTS, {
        filter,
        pagination: {page, perPage},
        sort,
    });

    const setFilters = (newFilters) => {
        setFilter({...defaultFilter, ...newFilters})
    };

    const onSelectCtx = (val) => {
        setSelectedIds(val);
    }
    const onToggleItem = (item) => {
        if (selectedIds.includes(item)) {
            //Remove
            setSelectedIds(selectedIds.filter(id => id !== item));
        } else {
            //Insert
            setSelectedIds([...selectedIds, item]);
        }
    }
    const onUnselectItems = () => {
        setSelectedIds([]);
    }

    // @ts-ignore
    const value: ListControllerResult = {
        data, total, page, perPage, setPerPage, setPage, filterValues: filter, setFilters, sort,
        selectedIds,
        onSelect: onSelectCtx,
        onToggleItem,
        onUnselectItems,
    };


    return <Dialog maxWidth="lg" onClose={onClose} open>
        <DialogTitle>Select Project Contacts</DialogTitle>
        <DialogContent>
            <ListContextProvider
                value={value}>
                <ListView
                    actions={<SearchFilter/>}
                    title={' '}
                    pagination={<PostPagination/>}
                >

                    <Datagrid
                        rowClick={false}
                        optimized={true}
                        bulkActionButtons={<BulkActionButtons onSelect={onSelect}/>}
                    >

                        <ReferenceField label="Client" source={"clientId"} reference={RESOURCE_CLIENTS} link={false}
                                        sortable={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <TextField source={"firstName"} label={'First Name'} sortable={false}/>
                        <TextField source={"lastName"} label={'Last Name'} sortable={false}/>
                        <EmailField source={"addresses[0].email"} label={'Email'} sortable={false}/>
                    </Datagrid>

                </ListView>
            </ListContextProvider>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" label="Close">
        <IconClose/>
      </Button>
    </DialogActions>
  </Dialog>;
}

export default MultipleClientContactSelectionDialog;
