import {AutocompleteInput, AutocompleteInputProps, useCreateSuggestionContext, useGetList} from "react-admin";
import React, {FC, useEffect, useState} from "react";
import {MAX_RECORD_PER_PAGE} from "../../provider/constants";
import {get} from 'lodash';
import {Button, Dialog, DialogActions, DialogContent, TextField} from '@mui/material';

const MANUAL_DUMMY_ID = -9999;
const NOT_EXIST_ID = -9998;

export interface CustomAutoCompleteProps extends AutocompleteInputProps {
  parentId?: number;
  parentName?: string;
  lookupResource: string
}

interface CreateItemProps {
 onItemCreate : (item:string) => void;
}
export const CreateItem : React.FC<CreateItemProps> = ({onItemCreate}) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onItemCreate(value);
    setValue('');
    onCreate(value);
  };

  return (
    <Dialog open onClose={onCancel}>
      <form>
        <DialogContent>
          <TextField
            label="New Item"
            value={value}
            onChange={event => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};


const CustomAutoCompleteLookup: FC<CustomAutoCompleteProps> = ({parentName,lookupResource, ...rest}) => {
  const [manualItem, setManualItem] = useState({id: 0, name: ''});
  const [finalData, setFinalData] = useState([]);

  const {data, isLoading, refetch} = useGetList(lookupResource, {
        pagination: {page: 1, perPage: MAX_RECORD_PER_PAGE},
        filter: {parentName: parentName || 'NoParent'}
      }
  );

  useEffect(() => {
    refetch();
  }, [parentName]);

  useEffect(() => {
    if (!isLoading && data) {
      //ToDo: Check this rest['record']
      const existingFieldValue = get(rest['record'], rest.source, undefined);

      let foundExistingValue = undefined;
      if (existingFieldValue) {
        foundExistingValue = data.find(dataItem => dataItem.name === existingFieldValue);
      }
      let _finalData = [...data];

      if (manualItem.id) {
        _finalData.push(manualItem);
      }

      if (existingFieldValue && !foundExistingValue) {
        _finalData.push({id: NOT_EXIST_ID, name: existingFieldValue});
      }

      setFinalData(_finalData)
    }
  }, [manualItem, isLoading, data]);


  return <AutocompleteInput
      optionValue="name"
      optionText="name"
      source={rest.source}
      choices={data ? finalData : []}
      create={<CreateItem onItemCreate={(item) => {
        setManualItem({id: MANUAL_DUMMY_ID, name: item});
      }}/>}
      {...rest}
  />;

}


export default CustomAutoCompleteLookup;
