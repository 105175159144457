import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {SelectColumnsButton, SelectColumnsButtonProps, useNotify,} from "react-admin";
import {useResourceContext, useStore} from "ra-core";
import {debounce, isEqual} from 'lodash';
import {getUser, setUser} from "../provider/authProvider";
import {LOCAL_STORE_VERSION, LOCAL_STORE_VERSION_KEY} from "../App";
import api from "../provider/api";
import {tryJsonParse} from "../provider/UtilityFunctions";

const SelectColumnsButtonAutoSave: React.FC<SelectColumnsButtonProps> = (props) => {
    const {preferenceKey} = props;
    const notify = useNotify();

    const resource = useResourceContext(props);
    const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

    const columnsKey = `RaStore.preferences.${finalPreferenceKey}.columns`;

    const [localColumns, setLocalColumns] = useState<any>(JSON.parse(localStorage.getItem(columnsKey)));
    const [columns] = useStore<string[]>(`preferences.${finalPreferenceKey}.columns`, []);

    const saveUserSettings = (userSettings) => {
        api.auth.updateUserSettings({userSettings: typeof userSettings !== 'string' ? JSON.stringify(userSettings) : userSettings})
            .then((response) => {
                const user = getUser();
                setUser({
                    ...user,
                    userSettings: response.data?.userSettings
                });
            })
            .catch((err) => {
                notify("Save column settings failed!", {type: "warning"});
                console.log("saveUserSettings errored:", err);
            });
    };

    const debounceSaveSettings = useCallback(debounce(saveUserSettings, 5000, {trailing: true, leading: false}), []);

    useEffect(() => {
        if (Array.isArray(columns) && columns.length > 0 && !isEqual(columns, localColumns)) {
            setLocalColumns(columns);
            const user = getUser();
            const userSettings = user.userSettings ? tryJsonParse(user.userSettings) : {[LOCAL_STORE_VERSION_KEY]: LOCAL_STORE_VERSION};

            //Update userSettings local variable
            userSettings[columnsKey] = columns;

            //Save it
            debounceSaveSettings(userSettings);
        }
    }, [columns]);


    return <SelectColumnsButton {...props} />;
};

export default SelectColumnsButtonAutoSave;

