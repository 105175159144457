import {Create, CreateProps, required, SaveButton, SimpleForm, TextInput, Toolbar,} from 'react-admin';
import React from "react";

const EditToolbar = (props) =>
  <Toolbar {...props} >
    <SaveButton
      label="Save"
      variant="text"
    />
  </Toolbar>;

export const FavouriteCreate: React.FC<CreateProps> = props => {
  return (<Create {...props} actions={false}>

          <SimpleForm warnWhenUnsavedChanges toolbar={<EditToolbar/>} style={{width: '100%'}}>
              <TextInput source="name" validate={[required()]}
                         sx={{display: 'inline-block', marginRight: '0.5rem', verticalAlign: 'middle'}}/>
          </SimpleForm>
      </Create>
  );
}

