import {FieldProps} from "ra-ui-materialui/src/field/types";
import {useDispatch, useSelector} from "react-redux";
import {Button, useNotify, useRecordContext, useRedirect} from "react-admin";
import {RootState} from "../../redux/reducers/initialState";
import {AxiosResponse} from "axios";
import api from "../../provider/api";
import {ROOM_CHAT_MESSAGES_INITIAL_STATE} from "../../redux/reducers/chatkitReducer";
import {sleep} from "../../provider/UtilityFunctions";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import React from "react";
import types from "../../redux/actions/types";
import {updateChatCandidates} from "../../redux/actions/chatkitActions";


const CandidateMessageButton: React.FC<FieldProps> = (props: FieldProps) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const record = useRecordContext();

  const candidateId = record?.id;

  const rooms = useSelector((state: RootState) => state.chatkit.rooms);
  const chatRooms = Object.values(rooms).filter(room => room.candidateId === candidateId);
  let roomId = chatRooms.length > 0 ? chatRooms[0].id : undefined;

  return <Button
      label={props.label != undefined ? ""+props.label : "Start chat"}
      variant="text"
      disabled={!Boolean(record?.lastLoginOnMobileAppDate)}
      onClick={async () => {
        if (!roomId) {
          const response: AxiosResponse = await api.chat.createChatRoom(candidateId);
          if (response.status === 200 && response.data.isSuccess) {
            const room = response.data.model;
            roomId = room.id;
            dispatch({
              type: types.chatkit.CHAT_ROOMS_ADD_ROOM,
              room: {...room, ...ROOM_CHAT_MESSAGES_INITIAL_STATE}
            });
            // @ts-ignore
            dispatch(updateChatCandidates(candidateId, notify))
          }
      }
      await sleep(100);
      redirect(`/messaging/${roomId}`);
    }}
  >
    <ChatBubbleIcon/>
  </Button>;
};

export default CandidateMessageButton;
