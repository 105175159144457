import * as React from "react";
import {MultiLevelMenu} from "@react-admin/ra-navigation";
import {
    RESOURCE_CANDIDATES,
    RESOURCE_CLIENTS,
    RESOURCE_FAVOURITE_LIST,
    RESOURCE_JOBS,
    RESOURCE_PLACEMENTS,
    RESOURCE_PROJECTS,
    RESOURCE_REPORTS,
    RESOURCE_USERS,
} from "./provider/restProvider";
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import Work from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MessagingMenu from "./components/Messaging/MessagingMenu";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import {useCanAccess} from "@react-admin/ra-rbac";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {MenuItemLink, useLogout, UserMenu} from "react-admin";
import SettingsIcon from '@mui/icons-material/Settings';
import {useProfile} from "./components/Users/ProfileEdit";
import ListIcon from '@mui/icons-material/List';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {clearLocalStorageListParams} from "./provider/UtilityFunctions";

const packageJson = require('../package.json')

// @ts-ignore
const getVersion = () => packageJson.version;

export const MyUserMenu = (props) => {
    const {profileVersion} = useProfile();
    const logout = useLogout();

    // @ts-ignore
  const myMenu = <MenuItemLink
      to="/my-profile"
      primaryText="My Profile"
      leftIcon={<SettingsIcon/>}
  />;

    // @ts-ignore
    const versionMenu = <MenuItemLink
        to=""
        primaryText={`Version ${getVersion()}`}
        onClick={() => {
        }}
    />;

    // @ts-ignore
    const clearLocalStorageListParamsMenu = <MenuItemLink
        to=""
        primaryText={'Clear search settings'}
        onClick={clearLocalStorageListParams}
    />;

    // // @ts-ignore
    // const clearLocalStorageColumnsMenu = <MenuItemLink
    //     to=""
    //     primaryText={'Clear columns settings'}
    //     onClick={clearLocalStorageColumns}
    // />;


    return (
        <UserMenu key={profileVersion} {...props}>
            {myMenu}
            {versionMenu}
            {clearLocalStorageListParamsMenu}
            {/*{clearLocalStorageColumnsMenu}*/}
            <MenuItemLink
                to=""
                primaryText={`Logout`}
                leftIcon={<ExitIcon/>}
                onClick={() => logout()}
            />
        </UserMenu>
  );
};

export const AppMenu = (props) => {
  const {canAccess: canAccessPages} = useCanAccess({
    action: "read",
    resource: RESOURCE_CANDIDATES,
  });

  const {canAccess: canAccessUsers} = useCanAccess({
    action: "read",
    resource: RESOURCE_USERS,
  });

  return (
      <MultiLevelMenu {...props}>
          {canAccessPages && <MenuItemLink
              key={"dashboard"}
              primaryText={"Dashboard"}
              id={"dashboard_menu"}
              to="/"
              leftIcon={<DashboardIcon/>}
          />}

          {canAccessPages && <MessagingMenu/>}

          {canAccessPages && <MenuItemLink
              primaryText={"Candidates"}
              to={`/${RESOURCE_CANDIDATES}`}
              id={"candidates_menu"}
              key={RESOURCE_CANDIDATES}
              leftIcon={<PeopleAlt/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Clients"}
              to={`/${RESOURCE_CLIENTS}`}
              id={"clients_menu"}
              key={RESOURCE_CLIENTS}
              leftIcon={<BusinessIcon/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Projects"}
              to={`/${RESOURCE_PROJECTS}`}
              id={"projects_menu"}
              key={RESOURCE_PROJECTS}
              leftIcon={<ShopTwoIcon/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Jobs"}
              to={`/${RESOURCE_JOBS}`}
              id={"jobs_menu"}
              key={RESOURCE_JOBS}
              leftIcon={<Work/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Placements"}
              to={`/${RESOURCE_PLACEMENTS}`}
              id={"placements_menu"}
              key={RESOURCE_PLACEMENTS}
              leftIcon={<AssignmentTurnedInIcon/>}
          />}

          {canAccessUsers && <MenuItemLink
              primaryText={"Users"}
              to={`/${RESOURCE_USERS}`}
              id={"users_menu"}
              key={RESOURCE_USERS}
              leftIcon={<SettingsApplicationsIcon/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Favourite Lists"}
              to={`/${RESOURCE_FAVOURITE_LIST}`}
              id={"favourite_menu"}
              key={RESOURCE_FAVOURITE_LIST}
              leftIcon={<ListIcon/>}
          />}

          {canAccessPages && <MenuItemLink
              primaryText={"Reports"}
              to={`/${RESOURCE_REPORTS}`}
              id={"reports_menu"}
              key={RESOURCE_REPORTS}
              leftIcon={<AssessmentOutlinedIcon/>}
          />}

      </MultiLevelMenu>
  );
};
