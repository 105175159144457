import React from "react";
import ChatList from "./ChatList";
import ChatMessages from "./ChatMessages";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {lightTheme} from "../../theme";


const classes = {
    paper: {
        padding: 1,
        color: lightTheme.palette.text.secondary,
        flexGrow: 1,
        height: "100%",
        maxHeight: 700
    },
    border: {
        borderRightStyle: 'solid',
        borderRightColor: lightTheme.palette.text.secondary,
        borderRightWidth: 1
    },
};


const Messaging = () => {

    return (
        <Paper sx={classes.paper}>
            <Grid container spacing={0} style={{height: "100%"}}>
                <Grid item xs={3} sx={classes.border}>
                    <ChatList/>
                </Grid>
                <Grid item xs={9}>
                    <ChatMessages/>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Messaging;
