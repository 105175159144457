import {AutocompleteInput, Create, CreateProps, email, required, SimpleForm, TextInput, useGetList,} from "react-admin";
import React, {useState} from 'react';

import {
  RESOURCE_CLIENTS,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
} from "../../provider/restProvider";
import {useLocation} from 'react-router';
import qs from 'query-string';
import {MAX_RECORD_PER_PAGE, US_COUNTRY_NAME} from "../../provider/constants";
import CustomAutoCompleteLookup from "../CustomAutoComplete/CustomAutoCompleteLookup";
import {CLIENT_CONTACTS_TAB_IND} from "../Clients/ClientEdit";
import Box from "@mui/material/Box";
import {commonStyles} from "../CommonStyles";
import Divider from "@mui/material/Divider";

export const ClientContactCreate: React.FC<CreateProps> = (props) => {
  const location = useLocation();
  const parsedSearch = qs.parse(location.search)
  const clientId = parsedSearch.clientId;

  const [country, setCountry] = useState(US_COUNTRY_NAME);
  const [state, setState] = useState('NoState');

  const {data: countryData, isSuccess: countryLoaded} = useGetList(RESOURCE_LOOKUP_COUNTRY, {
    pagination: {
      page: 1,
      perPage: MAX_RECORD_PER_PAGE
    }
  },);

  return (
      <Create title={' '}
              actions={false}
              redirect={`/${RESOURCE_CLIENTS}/${clientId}/${CLIENT_CONTACTS_TAB_IND}`}
              {...props}
      >
        <SimpleForm warnWhenUnsavedChanges
                    defaultValues={{clientId, addresses: [{country: US_COUNTRY_NAME}]}}
        >
          <Box sx={{display: 'block'}}>
            <TextInput source={"firstName"} label={'First Name'} validate={[required()]}
                       sx={commonStyles.formComponentDefaultMargins}
            />
            <TextInput source={"lastName"} label={'Last Name'} validate={[required()]}
                       sx={commonStyles.formComponentDefaultMargins}
            />
            <TextInput source={"jobTitle"} label={'Job Title'} validate={[required()]}
                        sx={commonStyles.formComponentDefaultMargins}
            />

            <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>

            <TextInput source={"addresses[0].email"} label={'Email'} validate={[email()]}
                       sx={commonStyles.formComponentDefaultMargins}
            />

            <TextInput source={"addresses[0].phone"} label={'Phone'}
                       sx={commonStyles.formComponentDefaultMargins}
            />

            <TextInput source={"addresses[0].alternativeEmail"} label={'Alternative Email'} validate={[email()]}
                       sx={commonStyles.formComponentDefaultMargins}
            />
            <TextInput source={"addresses[0].alternativePhone"} label={'Alternative Phone'}
                       sx={commonStyles.formComponentDefaultMargins}
            />
            <TextInput source={"addresses[0].address"} label={'Address'}
                       sx={commonStyles.formComponentDefaultMargins}
                       fullWidth={true}
            />
            <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>

            <AutocompleteInput
                validate={[required()]}
                optionValue="name"
                optionText="name"
                source="addresses[0].country"
                label={'Country'}
                onChange={(item: any) => setCountry(item)}
                choices={countryLoaded ? countryData : []}
                sx={commonStyles.formComponentDefaultMargins}
            />

        <CustomAutoCompleteLookup
            validate={[required()]}
            lookupResource={RESOURCE_LOOKUP_STATE}
            source="addresses[0].state"
            label={"State/Province"}
            onChange={(item: any) => setState(item)}
            parentName={country}
            sx={commonStyles.formComponentDefaultMargins}
        />

            <CustomAutoCompleteLookup
                lookupResource={RESOURCE_LOOKUP_CITY}
                source="addresses[0].city"
                label={"City/Town"}
                parentName={state}
                sx={commonStyles.formComponentDefaultMargins}
            />

          </Box>
      </SimpleForm>

    </Create>
  );
};
