import * as React from 'react';
import {DateInputProps, useInput} from "react-admin";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import TextField from "@mui/material/TextField";
import {capitalizeFirstLetter, convertDateToStr} from "../provider/UtilityFunctions";

// @ts-ignore
const CustomDateInput: React.FC<DateInputProps> = ({ source, sx, disabled, label }) => {
    const validate = (value: string) => {
        if (value === "Invalid Date") {
            return "Invalid Date";
        }
        return null;
    }

    const { field } = useInput({ source, validate });

    return (<DatePicker
        disableMaskedInput={true}
        label={label || capitalizeFirstLetter(source)}
        mask={"aaa/99/9999"}
        inputFormat="MMM/DD/YYYY"
        value={field.value || null}
        onChange={(newVal) => {
            field.onChange(convertDateToStr(newVal));
        }}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} sx={sx} id={source}/>}
    />);
};

export default CustomDateInput;