export const DEFAULT_AVATAR = 'https://harmoniqimages.s3.us-east-2.amazonaws.com/images/avatar/defaultavatar.png';
export const DEFAULT_DATE_FORMAT = "MMM/DD/YYYY";
export const MAX_RECORD_PER_PAGE = 5000;
export const MAX_EXPORT_PER_PAGE = 300000;
export const US_COUNTRY_NAME = "United States";

export const DATA_VALIDITY_DURATION = 15 * 60 * 1000; // 15 minutes

export const PROSPECT_SELECTED_AS_PROSPECT = 1;
export const PROSPECT_NOTIFIED_BY_RECRUITER = 2;
export const PROSPECT_NOT_INTERESTED = 3;
export const PROSPECT_INTERESTED_NOT_YET_SIGNED_RTR = 4;
export const PROSPECT_INTERESTED_RTR_SIGNED = 5;
export const PROSPECT_CANDIDATE_SUBMITTED = 6;
export const PROSPECT_CANDIDATE_DECLINED = 7;
export const PROSPECT_CANDIDATE_ACCEPTED = 8;
export const PROSPECT_PENDING_HELLOSIGN_APPROVAL = 9;

export const ROLE_CANDIDATE = 'Candidate';
export const ROLE_ADMIN = 'Admin';
export const ROLE_FIELD_MANAGER = 'FieldManager';