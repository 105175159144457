import {
    BooleanInput,
    Create,
    CreateProps,
    email,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import Box from "@mui/material/Box";
import {commonStyles} from "../CommonStyles";
import CustomDateInput from "../CustomDateInput";
import Divider from "@mui/material/Divider";
import React from "react";

const EditToolbar = (props) =>
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            variant="text"
        />
    </Toolbar>;


export const CandidateCreate: React.FC<CreateProps> = props => {

  return (<Create {...props} actions={false}>

          <SimpleForm warnWhenUnsavedChanges toolbar={<EditToolbar/>} style={{width: '100%'}}>
              <Box sx={{display: 'block'}}>
                  <TextInput source="title" validate={[required()]}
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="firstName" validate={[required()]}
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="lastName" validate={[required()]}
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="nickName"
                             sx={commonStyles.formComponentDefaultMargins}
                  />

                  <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>


                  <TextInput source="personalEmail" type="email"
                             validate={[email(), required()]}
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="workEmail" type="email" validate={[email()]}
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="mobile"
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="homePhone"
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="otherPhone"
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <TextInput source="birthday"
                             sx={commonStyles.formComponentDefaultMargins}
                  />

                  <BooleanInput source="doNotHire"
                                sx={commonStyles.formComponentDefaultMargins}
                  />
                  <BooleanInput source="hasMilitaryExperience"
                                sx={commonStyles.formComponentDefaultMargins}
                  />

                  <Divider style={{minWidth: '100%', marginTop: '0px', marginBottom: '15px', height: 0}}/>

                  <TextInput source="referredBy"
                             sx={commonStyles.formComponentDefaultMargins}
                  />
                  <CustomDateInput
                      source="availabilityDate"
                      sx={commonStyles.formComponentDefaultMargins}/>

                  <BooleanInput source="sendResetEmail" label={'Send Password Reset Email'}
                                sx={commonStyles.formComponentDefaultMargins}
                  />
              </Box>
      </SimpleForm>
    </Create>
  );
}

