import React, {useEffect, useState} from "react";
import moment from "moment";
import {get} from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {getValues, isEmpty} from "../../provider/UtilityFunctions";
import TextField from '@mui/material/TextField';
import {RootState} from "../../redux/reducers/initialState";
import {loadChatRooms, selectChatRoom, toggleArchivedState,} from "../../redux/actions/chatkitActions";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import {DEFAULT_AVATAR} from "../../provider/constants";
import Badge from '@mui/material/Badge';
import {useLocation} from 'react-router';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {lightTheme} from "../../theme";
import {useNotify} from "react-admin";


const classes = {
    listRoot: {
        width: '100%',
        backgroundColor: lightTheme.palette.background.paper,
        overflow: 'auto',
    },
    loader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        placeItems: 'center',
        marginTop: '50px'
    },
    chatListUser: {
        marginLeft: '10px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    userFullName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    }
};


const ChatList = () => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const chatState = useSelector((state: RootState) => state.chatkit);
    const [searchTxt, setSearchTxt] = useState("");
    const [filteredRoomIds, setFilteredRoomIds] = useState([]);
    const location = useLocation();

    const toggleArchived = () => {
        dispatch(toggleArchivedState());
        // @ts-ignore
        dispatch(loadChatRooms(notify));
    };

    const onChatRoomSelected = (selectedRoomId) => {
        if (selectedRoomId !== chatState.selectedRoomId) {
            // @ts-ignore
            dispatch(selectChatRoom(selectedRoomId, notify));
        }
    }

    useEffect(() => {
        if (!chatState.loading && !chatState.loaded) {
            // @ts-ignore
            dispatch(loadChatRooms(notify));
        } else if (chatState.selectedRoomId) {
            //Refresh selected room to clear un-read messages
            // @ts-ignore
            dispatch(selectChatRoom(chatState.selectedRoomId, notify));
        }
    }, []);

    //Select Chat room from path
    useEffect(() => {
    if (chatState.loaded && !chatState.selectedRoomId) {
      const parts = location.pathname.split("/");
      if (parts && parts.length === 3) {
          const roomId = parts[parts.length - 1];
          // @ts-ignore
          dispatch(selectChatRoom(roomId, notify));
      }
    }
  }, [chatState.loaded]);

    useEffect(() => {
        if (chatState.loaded) {
            const newFilteredRoomIds = Object.keys(chatState.rooms)
                .filter(roomId => {
                    const str = getValues(chatState.rooms[roomId]);
                    const result = str.search(new RegExp(searchTxt, "i"));
                    return isEmpty(searchTxt) || roomId === chatState.selectedRoomId || result > 0;
                })
              .sort((roomIdA, roomIdB) => {
                if (chatState.rooms[roomIdA].lastMessageDate > chatState.rooms[roomIdB].lastMessageDate)  return -1;
                else if (chatState.rooms[roomIdA].lastMessageDate < chatState.rooms[roomIdB].lastMessageDate) return 1;
                else return 0;
              });
            setFilteredRoomIds(newFilteredRoomIds);
        } else {
            setFilteredRoomIds([]);
        }
    }, [searchTxt, chatState.loaded, chatState.rooms]);

    return (
        <Box padding={1} alignItems={'start'} display={'flex'} flexDirection={'column'} maxHeight={675}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                <Typography variant={'h6'} color={"textSecondary"} align={'left'}>
                    Messaging
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={chatState.archived} onChange={toggleArchived}
                                         disabled={chatState.loading}/>}
                        label="Archived"
                    />
                </FormGroup>
            </Box>


            <TextField margin={'dense'}
                       variant="outlined"
                       disabled={!chatState.loaded}
                       value={searchTxt}
                       onChange={(e) => setSearchTxt(e.target.value)}
                       InputProps={{
                           startAdornment: (
                               <InputAdornment position="start">
                                   <SearchIcon/>
                               </InputAdornment>
                           ),
                       }}
                       fullWidth={true}
            >
            </TextField>

            {chatState.loading &&
                <Box sx={classes.loader}>
                    <CircularProgress/>
                </Box>}

            <Box sx={classes.listRoot}>
                <List component="nav" aria-label="main mailbox folders">
                    {filteredRoomIds.map((roomId, index) => {
                        const room = chatState.rooms[roomId];
                        const candidate = chatState.candidates[room.candidateId];
                        const candidateName = `${get(candidate, 'firstName')} ${get(candidate, 'lastName')}`;

                        return (
                            <React.Fragment key={roomId}>
                                <ListItem button key={roomId}
                                          selected={chatState.selectedRoomId === roomId}
                                          onClick={() => onChatRoomSelected(roomId)}
                                >

                                    <Badge badgeContent={room.unreadMessages} color="secondary">
                                        <ListItemIcon
                                            // onMouseEnter={(event) => handlePopoverOpen(room.candidateId, event)}
                                        >
                                            <Avatar alt={candidateName}
                                                    src={`${get(candidate, 'avatarURL')}` || DEFAULT_AVATAR}
                                            />
                                        </ListItemIcon>
                                    </Badge>

                                    <Box sx={classes.userFullName}>
                                        <p>{candidateName}</p>
                                        <Typography variant={'subtitle2'} color={"textSecondary"} align={'right'}>
                                            {moment(room.lastMessageDate).local().format("MMM D")}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                {index < (filteredRoomIds.length - 1) && <Divider key={roomId * 100}/>}
                            </React.Fragment>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );
};

ChatList.propTypes = {};

export default ChatList;
