import {SavedQueriesList, SimpleForm, useStore} from "react-admin";
import {Card, CardContent} from "@mui/material";
import SidebarFilter from "./SidebarFilter";
import Box from "@mui/material/Box";

export const FilterSidebar = ({ availableFilters }) => {
  const [sideFilters] = useStore("candidates.sideFilters", false);


  return sideFilters ? (
    <Card sx={{ order: -1, mr: 1, mt: 2, mb: 6.5, minWidth: 300 }}>
      <CardContent sx={{ padding: 0 }}>
          <SimpleForm
              toolbar={false}
              sx={{
                  display: "flex",
                  justifyContent: "stretch",
                  alignItems: "stretch",
                  flexDirection: "column",
              }}
          >
              <Box sx={{
                  '&.MuiBox-root': {
                      width: '100%',
                      pb: '10px',
                      borderBottom: '2px solid grey'
                  }
              }}>
                  <SavedQueriesList/>
              </Box>

              {availableFilters.map((filter, index) => {
                  return <SidebarFilter key={index} filter={filter}/>;
              })}
          </SimpleForm>
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};
