

import { BooleanInput, FormDataConsumer, TextInput } from "react-admin"
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext } from "react-hook-form";

/**
 * RA text input component that can be disabled with a toggle switch
 * also has optional autocomplete property choices
 */

interface ToggleableTextInputProps {
  fieldName: string,
  defaultValue: string,
  enabled: boolean,
  choices?: Array<{label: string, value: string}>,
  required?: boolean
}

const TogglableTextInput = ({fieldName, defaultValue, enabled, choices, required=false}: ToggleableTextInputProps) => {
  const {setValue} = useFormContext()
  return (
    <FormDataConsumer>
    {({ formData }) => (
      <Box sx={{display: 'flex', alignItems:'center', width: '100%', '& div:last-child': {width: '100%'}}}>
        <BooleanInput
          source={fieldName+'-enabled'} 
          defaultValue={enabled}
          label={false}
          onClick={() => setValue(fieldName, defaultValue)} />
          {
            choices ?
              <Autocomplete
                options={choices}
                freeSolo={true}
                disabled={!formData[fieldName+'-enabled']}
                value={formData[fieldName]}
                onChange={(e, value) => setValue(fieldName, value)}
                renderInput={(params) => <TextInput 
                  {...params} 
                  disabled={!formData[fieldName+'-enabled']}
                  source={fieldName}
                  required={required ? formData[fieldName+'-enabled'] : false}
                  defaultValue={defaultValue}
                  />}
                />
            :
              <TextInput 
                source={fieldName} 
                disabled={!formData[fieldName+'-enabled']}
                required={required ? formData[fieldName+'-enabled'] : false}
                parse={value => formData[fieldName+'-enabled'] ? value : null}
                />
          }

      </Box>
    )}
  </FormDataConsumer>
  )
}

export default TogglableTextInput
