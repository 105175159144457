import * as React from "react";
import PropTypes from "prop-types";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Identifier, useGetMany, useNotify, useRefresh, useUnselectAll } from "react-admin";
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import {sleep} from "../../provider/UtilityFunctions";
import {AxiosResponse} from "axios";
import api from "../../provider/api";

const removeCandidates = async (selectedIds: Identifier[]) => {
  return Promise.all(
      selectedIds.map(async (selectedCandidateId) => {
        try {
          const response: AxiosResponse = await api.candidates.delete(
              selectedCandidateId
        );
        if (response.status === 200) {
          const { data } = response;
          return Promise.resolve({
            data,
          });
        } else {
          return Promise.reject(`Api returned ${response.status}`);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    })
  );
};

export type DeleteMultipleButtonProps = {
  selectedIds: Identifier[];
};

const DeleteMultipleButton: React.FC<DeleteMultipleButtonProps> = ({ selectedIds = [] }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CANDIDATES);
  const {data, isLoading, error} = useGetMany(
      RESOURCE_CANDIDATES,
      {ids: selectedIds}
  );

  const onClicked = async () => {
    while (isLoading) {
      await sleep(100);
      console.log(
          "Waiting for getMany resource is ready:",
          RESOURCE_CANDIDATES
      );
    }
    if (!error && data && data.length > 0) {
      removeCandidates(selectedIds);

      notify("Candidates deleted", {type: "info", undoable: false});
      refresh();
      unselectAll();
    }
  };

  return (
      <Button label="Delete Candidates" onClick={onClicked} disabled={isLoading}>
        <DeleteIcon/>
      </Button>
  );
};

DeleteMultipleButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DeleteMultipleButton;
