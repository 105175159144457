import * as React from 'react';
import {useState} from 'react';
import {
  Datagrid,
  ListContextProvider,
  ListView,
  ResourceContextProvider,
  SortPayload,
  TextField,
  useGetList
} from 'react-admin';
import {RESOURCE_CANDIDATES,} from "../../provider/restProvider";
import LinkField from "../LinkField";
import {typographyProps} from "./Dashboard";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CandidateMessageButton from "../Candidate/CandidateMessageButton";

const FilterForm = () => {
  return (
      <Box display={'flex'} justifyContent={'space-between'} m={0} width={'100%'}>
        <Typography {...typographyProps}>
          New Candidates
        </Typography>
      </Box>
  )
};


export const NewCandidates = () => {
  const filterValues = {
    notMessaged: {
      fieldName: "NotMessaged",
      fieldValue: "NotMessaged",
      condition: "=="
    },
    deviceId: {
      fieldName: "DeviceId",
      fieldValue: null,
      condition: "!="
    }
  };

  const [filter, setFilter] = useState(filterValues);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = {field: 'availabilityDate', order: 'ASC'} as SortPayload;

  const {data, total, isLoading} = useGetList(RESOURCE_CANDIDATES, {
    filter,
    pagination: {page, perPage},
    sort,
    meta: {
      useSmallModel: true
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

// @ts-ignore
  const listCtx: ListControllerResult = {
    data, total, page, perPage, setPerPage, setPage, filterValues, sort, defaultTitle: ' '
  }
  // @ts-ignore
  return <ResourceContextProvider value={RESOURCE_CANDIDATES}>
  <ListContextProvider value={listCtx}>
    <ListView actions={<FilterForm/>}>
      <Datagrid rowClick={false} resource={RESOURCE_CANDIDATES} bulkActionButtons={false}>
        <LinkField source="id" label={"Candidate"} resource={RESOURCE_CANDIDATES}>
          <TextField source="candidateName" sortable={false}/>
        </LinkField>
        <CandidateMessageButton label={''} source={''}/>
      </Datagrid>
    </ListView>
  </ListContextProvider> </ResourceContextProvider>;

};
