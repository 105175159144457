import * as React from 'react';
import Box from '@mui/material/Box';
import {AvailableCandidates} from "./AvailableCandidates";
import Divider from "@mui/material/Divider";
import {TypographyProps} from "@mui/material/Typography/Typography";
import moment from "moment";
import {DEFAULT_DATE_FORMAT} from "../../provider/constants";
import {useCanAccess} from "@react-admin/ra-rbac";
import {RESOURCE_CANDIDATES} from "../../provider/restProvider";
import {PlacementEnding} from "./PlacementEnding";
import {PlacementOnGoing} from "./PlacementOnGoing";
import {NewCandidates} from "./NewCandidates";

export const ALL_1970 = 'Jan/01/1970';
export const ALL_2099 = 'Jan/01/2099';
export const pastChoices = [
  {
    id: ALL_1970,
    name: 'All'
  },
  {
    id: moment().subtract(1, 'months').format(DEFAULT_DATE_FORMAT),
    name: '1 Month'
  },
  {
    id: moment().subtract(3, 'months').format(DEFAULT_DATE_FORMAT),
    name: '3 Months'
  },
  {
    id: moment().subtract(5, 'months').format(DEFAULT_DATE_FORMAT),
    name: '5 Months'
  },
];

export const featureChoices = [
  {
    id: ALL_2099,
    name: 'All'
  },
  {
    id: moment().add(1, 'months').format(DEFAULT_DATE_FORMAT),
    name: '1 Month'
  },
  {
    id: moment().add(3, 'months').format(DEFAULT_DATE_FORMAT),
    name: '3 Months'
  },
  {
    id: moment().add(5, 'months').format(DEFAULT_DATE_FORMAT),
    name: '5 Months'
  },
];

export const dashboardClasses = {
  selectInput: {
    "& .MuiFormHelperText-root": {
      display: 'none'
    }
  }
};

export const typographyProps: TypographyProps = {
  variant: "h5",
  color: "textSecondary",
  align: 'center',
  style: {marginBottom: '5px', marginTop: '5px',}
}
export const Dashboard = () => {
  const {canAccess} = useCanAccess({
    action: "read",
    resource: RESOURCE_CANDIDATES,
  });

  return (
      canAccess ? <Box display={'flex'} flexWrap={'wrap'} style={{overflow: 'hidden'}}>
        <Box flex={1} mr="0.5em">
          <NewCandidates/>
        </Box>

        <Box flex={1} ml="0.5em" style={{overflow: 'hidden'}}>
          <AvailableCandidates/>
        </Box>

        <Divider style={{minWidth: '100%', margin: '15px', height: 0}}/>

        <Box flex={1} mr="0.5em" style={{overflow: 'hidden'}}>
          <PlacementEnding/>
        </Box>

        <Box flex={1} ml="0.5em" style={{overflow: 'hidden'}}>
          <PlacementOnGoing/>
        </Box>
      </Box> : <></>
  );
};
