import {PublicFieldProps} from "ra-ui-materialui/src/field/types";
import * as React from 'react';
import Box from "@mui/material/Box";

interface Props extends PublicFieldProps {

}

// @ts-ignore
const ActionsColumn: FC<Props> = ({children}) => {
    return <Box sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    }}>
        {children}
    </Box>;
};

export default ActionsColumn;

