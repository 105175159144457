import {
    BooleanInput,
    Button,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditProps,
    email,
    FieldProps,
    Link,
    NumberField,
    NumberInput,
    ReferenceManyField,
    required,
    RichTextField,
    SaveButton,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    useNotify,
    useRecordContext,
    useRefresh,
} from "react-admin";
import CustomDateInput from "../CustomDateInput";
import {
    RESOURCE_CANDIDATE_ADDRESSES,
    RESOURCE_CANDIDATE_CLEARANCES,
    RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
    RESOURCE_CANDIDATE_COMMENTS,
    RESOURCE_CANDIDATE_DOCUMENTS,
    RESOURCE_CANDIDATE_MEMBERSHIPS,
    RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
    RESOURCE_CANDIDATE_PLACEMENTS,
    RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
    RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
    RESOURCE_CANDIDATE_SKILLS,
    RESOURCE_CANDIDATE_SUBINDUSTRIES,
    RESOURCE_CANDIDATE_WORK_EXPERIENCE,
    RESOURCE_CANDIDATES,
    RESOURCE_LOOKUP_CLEARANCE,
    RESOURCE_LOOKUP_CLIENT_EXPERIENCE,
    RESOURCE_LOOKUP_MEMBERSHIPS,
    RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE,
    RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
    RESOURCE_LOOKUP_PROJECT_EXPERIENCE,
    RESOURCE_LOOKUP_SKILL,
    RESOURCE_LOOKUP_SUBINDUSTRY,
    RESOURCE_LOOKUP_WORK_EXPERIENCE,
    RESOURCE_PLACEMENTS,
} from "../../provider/restProvider";
import ImportSkillsDialog from "../Skills/ImportSkillsDialog";
import DownloadButton from "./DownloadButton";
import ActionsColumn from "../ActionsColumn";
import {UploadDocument} from "./UploadDocument";
import {LazyFormTab} from "../LazyFormTab/LazyFormTab";
import {EditableDatagrid} from "@react-admin/ra-editable-datagrid";
import React, {useEffect} from "react";
import CandidateMessageButton from "./CandidateMessageButton";
import AddressRowForm from "./AddressRowForm";
import {US_COUNTRY_NAME} from "../../provider/constants";
import api from "../../provider/api";
import LockIcon from '@mui/icons-material/Lock';
import {isEmpty} from "../../provider/UtilityFunctions";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {ID_SEPARATOR} from "../../provider/restProviderHelper";
import useClipboard from "react-use-clipboard";

import {placementRowStyle} from "../Placements/PlacementsList";

import Box from "@mui/material/Box";
import {LookupRowForm} from "./CandidateCommon";
import Divider from "@mui/material/Divider";
import {commonStyles} from "../CommonStyles";
import {useParams} from "react-router-dom";
import {CommentCreate} from "../CommentCreate";
import '../CommonCss.css';
import CustomBooleanField from "../CustomBooleanField";
import ParseCVButton from "./ParseCVButton";

export const PasswordResetButton = () => {
    const notify = useNotify();
    const [pwdResetUrl, setPwdResetUrl] = React.useState("");
    const [copied, setCopied] = useClipboard(pwdResetUrl);
    const record = useRecordContext();
    useEffect(() => {
        if (copied) {
            notify("Password reset link copied to clipboard", {
                type: "info",
                undoable: false,
      });
    }
  }, [copied]);

  return (
      <React.Fragment>
        <Button
            style={{marginLeft: "20px"}}
            label={"Send Pwd reset link"}
            variant="text"
            disabled={!Boolean(record?.userId)}
            onClick={async () => {
                try {
                    const response = await api.auth.forgotPasswordCandidate(record?.userId);

                    if (response.status === 200) {
                        const {data: {message}} = response;
                        if (message && message.includes('https')) {
                            setPwdResetUrl(message);
                        }

                        notify("Password reset link has been sent", {
                            type: "info",
                            undoable: false,
                        });
                    } else {
                  notify(`Request failed with ${response.status}`, {
                    type: "warning",
                    undoable: false,
                  });
                }
              } catch (e) {
                notify("Request failed, try again!", {
                  type: "warning",
                  undoable: false,
                });
              }
            }}
        >
          <LockIcon/>
        </Button>
        <Button
            style={{marginLeft: "20px"}}
            label={"Copy link"}
            variant="text"
            disabled={isEmpty(pwdResetUrl)}
            onClick={setCopied}
        >
          <FileCopyOutlinedIcon/>
        </Button>
      </React.Fragment>
  );
};

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      style={{ marginRight: "20px" }}
      label="Save"
      variant="text"
    />
    <CandidateMessageButton />
    <PasswordResetButton />
  </Toolbar>
);


export interface CandidateEditProps extends EditProps {
  syncWithLocation: undefined | boolean;
}

export const addressRowStyle = (record) => {
  if (record && record.isDefault) {
    return {
      borderLeftColor: "black",
      borderLeftWidth: 8,
      borderLeftStyle: 'solid',
    };
  } else {
    return {
      borderLeftWidth: 8,
    };

  }
};

const SetDefaultAddress = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();

    const parts = ("" + record?.id).split(ID_SEPARATOR);
    const addressId = parseInt(parts[0]);
    const candidateId = parseInt(parts[1]);

    return <Button
        label={"Default"}
        variant="text"
        disabled={record?.isDefault}
        onClick={() => {
            api.candidates.setDefaultAddress(candidateId, addressId)
                .then((response) => {
                    if (response.status === 200) {
                        refresh();
                    } else {
                        notify('Try again, please', {type: 'warning', undoable: false});
                    }
                })
                .catch(() => {
                    notify('Try again, please', {type: 'warning', undoable: false});
              });
        }}
    />;
};

const PlacementLinkField: React.FC<FieldProps> = () => {
  const record = useRecordContext();

  return <Link to={`/${RESOURCE_PLACEMENTS}/${record?.id}`} target="_blank">
     <NumberField key="id" source="id" label={'Placement ID'} sortable={false}/>
  </Link>;
};

export const CandidateEdit: React.FC<CandidateEditProps> = ({
                                                              syncWithLocation,
                                                              ...props
                                                            }) => {
    const {id} = useParams();
    const record = useRecordContext();
    const candidateId = id || record?.id;
    const [skillsDialogOpen, setSkillsDialogOpen] = React.useState(false)
    const [document, setDocument] = React.useState(false)

    const setDocumentId = (document) => {
      setDocument(document)
      setSkillsDialogOpen(true)
    }
    const closeDialog = () => {
      setSkillsDialogOpen(!skillsDialogOpen)
      setDocument(false)
    }
    return (
        <Edit
            {...props}
            title={syncWithLocation === undefined ? "" : " "}
            actions={false}
            mutationMode="pessimistic"
        >
            <>
                <TabbedForm
                    warnWhenUnsavedChanges
                    toolbar={<EditToolbar/>}
                    style={{width: "100%"}}
                    syncWithLocation={
                        syncWithLocation === undefined ? true : syncWithLocation
                    }
                    sx={commonStyles.tabbedFormBoldHeader}
                >

                    <LazyFormTab label="Header">
                        <NumberInput
                            source="id"
                            label={"Candidate ID"}
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled
                        />
                        <TextInput source="title"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="firstName"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="lastName"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="nickName"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <Divider
                            style={{
                                minWidth: '100%',
                                marginTop: '0px',
                                marginBottom: '15px',
                                height: 0,
                                borderWidth: 0
                            }}/>
                        <TextInput
                            source="personalEmail"
                            type="email"
                            sx={commonStyles.formComponentDefaultMargins}
                            validate={[required(), email()]}
                        />
                        <TextInput
                            source="workEmail"
                            type="email"
                            sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="mobile"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="homePhone"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="otherPhone"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />
                        <TextInput source="birthday"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />

                        <BooleanInput
                            source="doNotHire"
                            sx={commonStyles.formComponentDefaultMargins}
                        />
                        <BooleanInput
                            source="hasMilitaryExperience"
                            sx={commonStyles.formComponentDefaultMargins}
                        />
                        <BooleanInput
                            source="completedWizard"
                            label="Completed Wizard"
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />

                        <TextInput source="referredBy"
                                   sx={commonStyles.formComponentDefaultMargins}
                        />

                        <Divider
                            style={{
                                minWidth: '100%',
                                marginTop: '0px',
                                marginBottom: '15px',
                                height: 0,
                                borderWidth: 0
                            }}/>

                        <CustomDateInput
                            source="availabilityDate"
                            sx={commonStyles.formComponentDefaultMargins}
                        />
                        <CustomDateInput
                            source="creationDate"
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />
                        <CustomDateInput
                            source="lastModifiedDate"
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />
                        <CustomDateInput
                            source="lastLoginOnMobileAppDate"
                            label={"Last login on mobile ppp"}
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />
                        <CustomDateInput
                            source="availabilityDateLastUpdated"
                            label={"Availability last updated"}
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />


                        <Divider
                            style={{
                                minWidth: '100%',
                                marginTop: '0px',
                                marginBottom: '15px',
                                height: 0,
                                borderWidth: 0
                            }}/>

                        <TextInput
                            source="createdByName"
                            label="Created by"
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />
                        <TextInput
                            source="updatedByName"
                            label="Updated by"
                            sx={commonStyles.formComponentDefaultMargins}
                            disabled={true}
                        />


                    </LazyFormTab>

                    <LazyFormTab label="comments">
                        <ReferenceManyField
                            reference={RESOURCE_CANDIDATE_COMMENTS}
                            target="comments"
                            label=""
                            perPage={100}
                            sort={{field: "lastModifiedDate", order: "DESC"}}
                        >
                            <Datagrid
                                rowClick={false}
                                bulkActionButtons={false}
                                sx={{
                                    ...commonStyles.subGridDefaultStyle,
                                    maxHeight: '500px',
                                    overflowY: 'auto'
                                }}
                            >
                                <RichTextField source="text" label="Comment"/>
                                <RichTextField
                                    source="fieldManagerName"
                                    label="Comment Authour"
                                />
                                <TextField source="lastModifiedDate"/>
                                <DeleteWithConfirmButton redirect={false} label={""}/>
                            </Datagrid>
                        </ReferenceManyField>

                        <CommentCreate resource={RESOURCE_CANDIDATE_COMMENTS}/>
                    </LazyFormTab>

                    <LazyFormTab label="Documents">
                        <ReferenceManyField
                            reference={RESOURCE_CANDIDATE_DOCUMENTS}
                            target="documents"
                            label=""
                            perPage={100}
                            sort={{field: "name", order: "ASC"}}
                        >
                            <Datagrid
                                rowClick={false}
                                bulkActionButtons={false}
                                sx={commonStyles.subGridDefaultStyle}
                            >
                                <TextField
                                    source="documentType"
                                    label="Type"
                                    sx={{textTransform: 'capitalize'}}
                                />
                                <TextField source="name" label="File Name"/>
                                <DateField source="creationDate"/>
                                <ActionsColumn>
                                    <ParseCVButton
                                        label={"Parse CV"}
                                        source={"id"}
                                        onClick={setDocumentId}
                                        className="parse-cv-button"/>
                                    <DownloadButton source="url" label=""/>
                                    <DeleteWithConfirmButton redirect={false} label={""}/>
                                </ActionsColumn>
                            </Datagrid>
                        </ReferenceManyField>

                        <UploadDocument
                            idField={"candidateId"}
                            idValue={'' + candidateId}
                            resource={RESOURCE_CANDIDATE_DOCUMENTS}
                        />
                    </LazyFormTab>

                    <LazyFormTab label="Addresses">
                        <ReferenceManyField
                            reference={RESOURCE_CANDIDATE_ADDRESSES}
                            target="addresses"
                            label=""
                            perPage={100}
                        >
                            <EditableDatagrid
                                bulkActionButtons={false}
                                editForm={<AddressRowForm hasAddressType={true} hasUnitNumber={true} hasCity={true}/>}
                                createForm={
                                    <AddressRowForm
                                        hasAddressType={true}
                                        hasUnitNumber={true}
                                        hasCity={true}
                                        defaultValues={{
                                            candidateId,
                                            country: US_COUNTRY_NAME
                                        }}
                                    />
                                }
                                noDelete={true}
                                rowSx={addressRowStyle}
                                className={"EditableGridDefaultHeader"}
                                rowClick={false}

                            >
                                <TextField source="addressType" sortable={false}/>
                                <TextField source="address" sortable={false}/>
                                <TextField source="unitNumber" sortable={false}/>
                                <TextField source={"country"} sortable={false}/>
                                <TextField
                                    source="state"
                                    label="State/Province"
                                    sortable={false}
                                />
                                <TextField source="city" label="City/Town" sortable={false}/>
                                <TextField source={"zip"} sortable={false}/>

                                <ActionsColumn>
                                    <SetDefaultAddress/>
                                    <DeleteWithConfirmButton redirect={false} label={""}/>
                                </ActionsColumn>

                            </EditableDatagrid>
                        </ReferenceManyField>
                    </LazyFormTab>

                    <LazyFormTab label="Experience">
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} ml="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}
                                    target={RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}
                                    label=" "
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label="Oem Experience Type"
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                        <Divider style={{minWidth: "100%", margin: "20px", height: 0}}/>
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} mr="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}
                                    target={RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label="Primary Work Experience"
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_WORK_EXPERIENCE}
                                    target={RESOURCE_CANDIDATE_WORK_EXPERIENCE}
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_WORK_EXPERIENCE}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_WORK_EXPERIENCE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label="Work Experience"
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                        <Divider style={{minWidth: "100%", margin: "20px", height: 0}}/>
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} mr="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}
                                    target={RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_CLIENT_EXPERIENCE}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_CLIENT_EXPERIENCE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label="Client Experience"
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}
                                    target={RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_PROJECT_EXPERIENCE}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_PROJECT_EXPERIENCE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label="Project Experience"
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                    </LazyFormTab>

                    <LazyFormTab label="Clearance/Memberships">
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} mr="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_CLEARANCES}
                                    target="clearances"
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm lookupResource={RESOURCE_LOOKUP_CLEARANCE}/>
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_CLEARANCE}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField source="name" label="Clearence" sortable={false}/>
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_MEMBERSHIPS}
                                    target="memberships"
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_MEMBERSHIPS}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_MEMBERSHIPS}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField
                                            source="name"
                                            label={"Membership"}
                                            sortable={false}
                                        />
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                    </LazyFormTab>

                    <LazyFormTab label="Skills/Industries" sx={{justifyContent: 'start'}}>
                        {/*<Box display="flex" sx={{*/}
                        {/*    minWidth: "100%",*/}
                        {/*    borderBottom: "black 1px solid",*/}
                        {/*    marginBottom: "10px",*/}
                        {/*    paddingBottom: "10px",*/}
                        {/*    justifyContent: "end"*/}
                        {/*}}>*/}
                        {/*    <Button color="secondary" label="Import Skills from CV" startIcon={<GradingIcon/>}*/}
                        {/*            onClick={() => setSkillsDialogOpen(true)}/>*/}
                        {/*</Box>*/}
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} mr="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_SKILLS}
                                    target="skills"
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm lookupResource={RESOURCE_LOOKUP_SKILL}/>
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_SKILL}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField source="name" label="Skill" sortable={false}/>
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                            <Box flex={1} ml="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATE_SUBINDUSTRIES}
                                    target="subindustries"
                                    label=""
                                    perPage={100}
                                >
                                    <EditableDatagrid
                                        bulkActionButtons={false}
                                        className={"EditableGridBoldHeader"}
                                        editForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
                                            />
                                        }
                                        createForm={
                                            <LookupRowForm
                                                lookupResource={RESOURCE_LOOKUP_SUBINDUSTRY}
                                                defaultValues={{candidateId}}
                                            />
                                        }
                                        noDelete={true}
                                        rowClick={false}
                                    >
                                        <TextField source="name" label="Industry" sortable={false}/>
                                        <DeleteWithConfirmButton redirect={false} label={""}/>
                                    </EditableDatagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                    </LazyFormTab>
                    <LazyFormTab label="Placements" sx={{justifyContent:'start', display: "flex"}}>
                        <Box display="flex" sx={{minWidth: "100%"}}>
                            <Box flex={1} mr="0.5em">
                                <ReferenceManyField
                                    reference={RESOURCE_CANDIDATES}
                                    target={RESOURCE_CANDIDATE_PLACEMENTS}
                                    label=""
                                    perPage={100}
                                >
                                    <Datagrid
                                        bulkActionButtons={false}
                                        optimized={true}
                                        rowClick={false}
                                        rowSx={placementRowStyle}
                                        sx={commonStyles.defaultGridStyle}
                                        empty={<div>No Placements to display</div>}>
                                        <PlacementLinkField label={"ID"}/>
                                        <TextField key="jobTitle" source="jobTitle" sortable={false}/>
                                        <TextField key="client" source="clientName" sortable={false}/>
                                        <TextField key="project" source="projectName" sortable={false}/>
                                        <TextField key="startDate" source="startDate" label={'Start'} sortable={false}/>
                                        <TextField key="endDate" source="endDate" label={'End'} sortable={false}/>
                                        <NumberField key="payRate" source="straightTimePayRate"
                                                     label={'Straight time pay Rate'} sortable={false}/>
                                        <NumberField key="billRate" source="straightTimeBillRate"
                                                     label={'Straight time bill rate'} sortable={false}/>
                                        <NumberField key="perDiem" source="perDiem" label={'Per Diem'}
                                                     sortable={false}/>
                                        <NumberField key="duration" source="duration" sortable={false}/>
                                        <CustomBooleanField
                                            isReversed
                                            label="Archived"
                                            source="isActive"
                                            sortable={false}/>,
                                    </Datagrid>
                                </ReferenceManyField>
                            </Box>
                        </Box>
                    </LazyFormTab>
                </TabbedForm>
                <ImportSkillsDialog isOpen={skillsDialogOpen} onClose={closeDialog} document={document}/>
            </>
    </Edit>
  );
};
