import {blueGrey} from '@mui/material/colors';
import {enUS} from '@mui/material/locale'
import {defaultTheme} from "react-admin";
import {createTheme} from '@mui/material/styles';

export const lightTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: 'light',
    background: {
      paper: '#ffffff',
      default: '#ffffff'
    },
    primary: {
      main: '#00688c',
    },
    secondary: {
      main: '#00688c',
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[600]
    },
  },
  sidebar: {
    width: 170,
    closedWidth: 55
  },
  components: {
    MuiPaper:{
      styleOverrides: {
        root:{
          borderRadius: '6px'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      }
    },
    MuiTextField: {
      defaultProps: {
        // margin: 'normal',
        variant: 'outlined',
        size: 'small',
      },
    },
  },
  overrides: {
    RaSidebar: null,
    MuiTab: {
      wrapper: {
        fontWeight: 'bold'
      }
    },
  }
}, enUS);
