import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import yellow from '@mui/material/colors/yellow';
import purple from '@mui/material/colors/purple';

export const RED = 'rgb(215, 68, 88)'; //'#f14158';
export const GREEN = green[500];
export const YELLOW = yellow[500];
export const ORANGE = '#ff7c26'
export const GREY = grey[500];
export const BLACK = '#000000';
export const PURPLE = purple[700];
