import {RESOURCE_CANDIDATES, RESOURCE_PLACEMENTS,} from "../../provider/restProvider";
import {
    BooleanField,
    BooleanInput,
    DatagridConfigurable,
    DeleteWithConfirmButton,
    EditButton,
    Filter,
    FilterProps,
    Link,
    List,
    ListProps,
    NumberField,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import ActionsColumn from "../ActionsColumn";
import {PlacementEdit} from "./PlacementEdit";
import {FieldProps} from "ra-ui-materialui/src/field/types";
import CustomDateInput from "../CustomDateInput";
import ExportButton from "../ExportButton";
import React, {CSSProperties} from "react";
import {isEmpty} from "../../provider/UtilityFunctions";
import ArchiveIcon from '@mui/icons-material/Archive';
import FreeTextSearchInput from "../FreeTextSearchInput";
import {commonStyles} from "../CommonStyles";
import {PostPagination} from "../PostPagination";
import moment from "moment";
import {RED} from "../../theme/colors";
import SelectColumnsButtonAutoSave from "../SelectColumnsButtonAutoSave";

const CandidateLinkField: React.FC<FieldProps> = ({source}) => {
    const record = useRecordContext();
    return <Link to={`/${RESOURCE_CANDIDATES}/${record?.candidateId}`}>
        <TextField source={source} sortable={false}/>
    </Link>;
};

// const defaultColumns: string[] = [
//     'ID',
//     'firstName',
//     'lastName',
//     'jobTitle',
//     'projectName',
//     'clientName',
//     'startDate',
//     'endDate',
//     'straightTimeBillRate',
//     'fieldManagerName',
// ];


const getBackGroundColor = (record) => {
    if (!record) return undefined;
    if (moment(record.endDate, 'MMM/DD/YYYY').toDate() < moment().toDate() && record.isActive) {

        return RED;
    }
    if (
        isEmpty(record["onboardingComplete"]) ||
        isEmpty(record["drugScreenComplete"]) ||
        isEmpty(record["physicalComplete"]) ||
        isEmpty(record["trainingComplete"]) ||
        isEmpty(record["backgroundCheckComplete"]) ||
        (isEmpty(record["clientTrainingComplete"]) === !(record["bypassClientTraining"])) //only one should be populated
    ) {
        return "rgb(255 207 176)";
    }

    return undefined;
};

export const placementRowStyle = (record) => {
    let style: CSSProperties = {};
    const color = getBackGroundColor(record);

    if (color) {
        style.backgroundColor = getBackGroundColor(record);
    }

    return style;
};


const PlacementFilter: React.FC<Omit<FilterProps, "children">> = (props) => {

  return (
      <Filter {...props} variant="outlined">
          <FreeTextSearchInput source="searchTerm" alwaysOn label="Free Text Search"/>
          <BooleanInput source="IsArchived" label="Archived" alwaysOn />
          <BooleanInput
              source="isPending"
              label="Pending"
              alwaysOn
              format={(v) => Boolean(v)}
          />
          <CustomDateInput source="startDate" label="Start Date"/>
          <CustomDateInput source="endDate" label="End Date"/>
      </Filter>
  );
};

const PlacementActions = () => (
    <TopToolbar>
        <PlacementFilter context={"button"}/>
        <ExportButton
            excludedColumns={[
                'candidateId', 
                'placementGuid', 
                'drugScreenComplete', 
                'backgroundCheckComplete', 
                'physicalComplete', 
                'trainingComplete', 
                'onboardingComplete', 
                'fieldManagerId', 
                'isActive', 
                'perDiem', 
                'msp'
            ]}
        />
        <SelectColumnsButtonAutoSave/>
    </TopToolbar>
);


const PlacementDetail = () => {
  const record = useRecordContext();

  return (
    <PlacementEdit
      resource={RESOURCE_PLACEMENTS}
      id={record?.id}
      syncWithLocation={false}
    />
  );
};

export const PlacementsList: React.FC<ListProps> = (props) => {
    return <List
        title={undefined}
        {...props}
        filters={<PlacementFilter/>}
        perPage={50}
        pagination={<PostPagination/>}
        filterDefaultValues={{
            IsActive: {
                fieldName: "IsActive",
                fieldValue: "true",
                condition: "=="
        }
      }}
        actions={<PlacementActions/>}
        sort={{field: "endDate", order: "DESC"}}
        sx={commonStyles.raFilterForm}
    >
        <DatagridConfigurable
            bulkActionButtons={false}
            rowClick="expand"
            // optimized={true}
            expand={<PlacementDetail/>}
            expandSingle
            rowSx={placementRowStyle}
            sx={commonStyles.defaultGridStyle}
        >

            <NumberField source="id" label={'Placement ID'} sortable={true}/>
            <CandidateLinkField source={"firstName"} sortable={true}/>
            <CandidateLinkField source={"lastName"} sortable={true}/>
            <TextField source="jobTitle" sortable={false}/>
            <TextField source="startDate" label={'Start'} sortable={true}/>
            <TextField source="endDate" label={'End'} sortable={true}/>
            <NumberField source="duration" sortable={true}/>
            <TextField source="onboardingComplete" sortable={true}/>
            <TextField source="backgroundCheckComplete"
                       sortable={true}/>
            <TextField source="physicalComplete" sortable={true}/>
            <TextField source="drugScreenComplete" sortable={true}/>
            <TextField source="trainingComplete" sortable={true}/>
            <TextField source="clientTrainingComplete" sortable={true}/>
            <BooleanField source="bypassClientTraining" sortable={true}/>
            <NumberField source="perDiem" sortable={true}/>
            <TextField source="projectName" sortable={true}/>
            <TextField source="clientName" sortable={true}/>
            <NumberField source="straightTimeBillRate" sortable={true}/>
            <NumberField source="overtimeBillRate" sortable={true}/>
            <NumberField source="doubleTimeBillRate" sortable={true}/>
            <NumberField source="straightTimePayRate" sortable={true}/>
            <NumberField source="overtimePayRate" sortable={true}/>
            <NumberField source="doubleTimePayRate" sortable={true}/>
            <TextField source="rotationId" sortable={true}/>
            <TextField source="rotationStartDate" sortable={true}/>
            <TextField source="clientContactName" sortable={false}/>
            <TextField source="fieldManagerName" label={"Field Manager"} sortable={true}/>

            <ActionsColumn label={'Actions'}>
                <EditButton label=""/>
                <DeleteWithConfirmButton
                    label=""
                    confirmTitle={'Archive Placement'}
                    confirmContent={'Are you sure to archive this item?'}
                    icon={<ArchiveIcon/>}/>
            </ActionsColumn>
        </DatagridConfigurable>
    </List>;
};
