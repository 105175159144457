import {
    DatagridConfigurable,
    ShowButton,
    List,
    ListProps,
    TextField
} from 'react-admin';
import ActionsColumn from "../ActionsColumn";
import React from "react";

export const ReportList: React.FC<ListProps> = props => {
    return (
        <List
            title="Report Lists"
            pagination={false}
            actions={false}
            sx={{marginTop: '18px'}}
            {...props}
        >
            <DatagridConfigurable
                bulkActionButtons={false}
                rowClick={false}
            >
                <TextField source="reportName" label={'Report Name'} sortable={false}/>
                <ActionsColumn label={'View'}>
                    <ShowButton label="" />
                </ActionsColumn>
            </DatagridConfigurable>
        </List>
    );
};
