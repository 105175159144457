import moment from "moment";
import {DEFAULT_DATE_FORMAT, MAX_RECORD_PER_PAGE, PROSPECT_CANDIDATE_SUBMITTED} from "../../provider/constants";
import {BLACK, GREEN, GREY, PURPLE, RED, YELLOW} from "../../theme/colors";
import {required, SelectInput, useGetList} from "react-admin";
import {RowForm} from "@react-admin/ra-editable-datagrid";

export const candidateRowStyle = (record) => {
    let style = {};
    if (!record) {
        return style;
    }
    return {
        ...style,
        borderLeftColor: getRowBorderColor(record),
        borderLeftWidth: 8,
        borderLeftStyle: 'solid',
    };
};


const getRowBorderColor = (record) => {

    const jobResponses = Array.isArray(record['jobResponses']) ? record['jobResponses'] : [];
    const submittedJob = jobResponses.find(jr => jr.prospectStatusId == PROSPECT_CANDIDATE_SUBMITTED);

    if (record.doNotHire) {
        return BLACK;
    } else if (submittedJob) {
        return PURPLE;
    } else if (record['availabilityDate']) {
        const availMoment = moment(record['availabilityDate'], DEFAULT_DATE_FORMAT);
        const nowMoment = moment();
        const months = availMoment.diff(nowMoment, 'months');

        if (availMoment.isBefore(nowMoment)) {
            return RED;
        } else if (months < 2) {
            return GREEN;
        } else if (months < 5) {
            return YELLOW;
        }
    }
    return GREY;
};

export const LookupRowForm = ({lookupResource, ...rest}) => {
    const {data, isSuccess: loaded} = useGetList(lookupResource, {
        pagination: {
            page: 1,
            perPage: MAX_RECORD_PER_PAGE,
        }
    });
    return (
        <RowForm {...rest}>
            <SelectInput
                validate={[required()]}
                variant={"outlined"}
                label={false}
                source="lookupId"
                choices={loaded ? data : []}
            />
        </RowForm>
    );
};
