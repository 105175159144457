import React, {createContext, useContext, useMemo, useState} from 'react';
import {
    ImageField,
    ImageInput,
    required,
    SaveButton,
    SaveContextProvider,
    SimpleForm,
    TextInput,
    Toolbar,
    useGetIdentity,
    useNotify
} from 'react-admin';
import {getUser, updatePhoneNumber, updateProfilePicture} from "../../provider/authProvider";
import {PasswordResetButton} from "../Candidate/PasswordResetButton";


interface profileVersionType {
    profileVersion: number;
    refreshProfile: () => void;
}

const ProfileContext = createContext<profileVersionType>({
    profileVersion: 0, refreshProfile: () => {
    }
});

export const ProfileProvider = ({children}) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1)
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const ACCEPTED_FILE_FORMATS = '.jpg, .jpeg, .png, .bmp';
const ACCEPTED_FILE_FORMATS_VALUES = ACCEPTED_FILE_FORMATS.split(',');

export const ProfileEdit = (props) => {
  const user = getUser();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
    const {refreshProfile} = useProfile();
    const {isLoading, identity, refetch} = useGetIdentity();

    const onSuccess = () => {
        setSaving(false);
        notify("Your profile has been updated", {type: "info"});
        refetch().then();
        refreshProfile();
    }

    const onFail = () => {
        setSaving(false);
        notify("Failed. Please try again!", {type: "info"});
    }

    const handleSave = (values) => {
        setSaving(true);
        if(values.avatar.rawFile)
        {
            updateProfilePicture(values).then((response) => {
                if (!response) {
                    onFail();
                }
            })
                .catch(() => onFail());
        }
        updatePhoneNumber(values).then((response) => {
            if (response) {
                onSuccess();

            } else {
                onFail();
            }
        })
            .catch(() => onFail());
  };

  const saveContext = useMemo(() => ({
    save: handleSave,
    saving
  }), [saving, handleSave]);

    if (!user || isLoading) {
        return <></>;
    }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        record={identity ? identity : {}}
        toolbar={<Toolbar {...props} >
          <SaveButton
            label="Save"
            variant="text"
          />
          <PasswordResetButton record={{userId: user.userId}}/>
        </Toolbar>}
      >
        <TextInput source="firstName" validate={required()} disabled={true}/>
        <TextInput source="lastName" validate={required()} disabled={true}/>
          <TextInput source="email" type="email" disabled={true}/>
          <TextInput source="phoneNumber" validate={required()} />
          <ImageInput
              source="avatar"
              label={'Avatar'}
              accept={{ "image/*": ACCEPTED_FILE_FORMATS_VALUES }}
              sx={{
                  width: '50%',
                  '& div div': {
                      textAlign: 'left'
                  }
              }}
              >
            <ImageField source="src" />
          </ImageInput>
      </SimpleForm>
    </SaveContextProvider>

  );
};
