import {
    AutocompleteInput,
    Create,
    CreateProps,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useGetList
} from 'react-admin';
import React, {useState} from "react";
import {
    RESOURCE_CLIENTS,
    RESOURCE_LOOKUP_CITY,
    RESOURCE_LOOKUP_COUNTRY,
    RESOURCE_LOOKUP_STATE
} from "../../provider/restProvider";
import {MAX_RECORD_PER_PAGE, US_COUNTRY_NAME} from "../../provider/constants";
import CustomAutoCompleteLookup from "../CustomAutoComplete/CustomAutoCompleteLookup";
import {commonStyles} from "../CommonStyles";
import Box from "@mui/material/Box";

const EditToolbar = (props) =>
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            variant="text"
        />
    </Toolbar>;


export const ProjectCreate: React.FC<CreateProps> = props => {

    const [country, setCountry] = useState(US_COUNTRY_NAME);
    const [state, setState] = useState('NoState');

    const {data: countryData, isSuccess: countryLoaded} = useGetList(RESOURCE_LOOKUP_COUNTRY, {
        pagination: {
            page: 1,
            perPage: MAX_RECORD_PER_PAGE
        }
    },);

    const {data: clientData, isSuccess: clientLoaded} = useGetList(RESOURCE_CLIENTS, {
        pagination: {
            page: 1,
            perPage: MAX_RECORD_PER_PAGE
        },
        sort: {field: 'name', order: 'ASC'}
    },);

    return (<Create {...props} actions={false}>

            <SimpleForm warnWhenUnsavedChanges toolbar={<EditToolbar/>} style={{width: '100%'}}
                        defaultValues={{addresses: [{country: US_COUNTRY_NAME}]}}
            >
                <Box sx={{display: 'block'}}>
                    <TextInput source="name" validate={[required()]}
                               sx={commonStyles.formComponentDefaultMargins}
                    />
                    <SelectInput
                        source="clientId"
                        validate={[required()]}
                        choices={clientLoaded ? clientData : []}
                        sx={{
                            ...commonStyles.formComponentDefaultMargins,
                            '& div': {
                                minWidth: '150px'
                            }
                        }}
                    />
                    <TextInput source="description" validate={[required()]} fullWidth={true} multiline
                               sx={commonStyles.formComponentDefaultMargins}
                    />


                    <TextInput source={"addresses[0].address"} label={'Address'} fullWidth={true}
                               sx={commonStyles.formComponentDefaultMargins}
                    />

                    <AutocompleteInput
                        validate={[required()]}
                        optionValue="name"
                        optionText="name"
                        source="addresses[0].country"
                        label={'Country'}
                        onChange={(item: any) => setCountry(item)}
                        choices={countryLoaded ? countryData : []}
                        sx={commonStyles.formComponentDefaultMargins}
                    />
                    <CustomAutoCompleteLookup
                        validate={[required()]}
                        lookupResource={RESOURCE_LOOKUP_STATE}
                        source="addresses[0].state"
                        label={"State/Province"}
                        onChange={(item: any) => setState(item)}
                        parentName={country}
                        sx={commonStyles.formComponentDefaultMargins}
                    />

                    <CustomAutoCompleteLookup
                        lookupResource={RESOURCE_LOOKUP_CITY}
                        source="addresses[0].city"
                        label={"City/Town"}
                        parentName={state}
                        sx={commonStyles.formComponentDefaultMargins}
                    />

                    <TextInput source="addresses[0].zip" label={'zip'}
                               sx={{...commonStyles.formComponentDefaultMargins, marginTop: '8px'}}
                    />

                </Box>
            </SimpleForm>
        </Create>
  );
}

