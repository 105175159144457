export const commonStyles = {
    raFilterForm: {
        '& .RaFilter-form': {
            width: '100%',
            alignItems: 'center'
        },
    },
    formComponentDefaultMargins: {
        marginRight: '10px',
        marginTop: '0',
        marginBottom: '0',
        display: 'inline-block',
        // minWidth: '222px',
        '& .MuiInputBase-root': {
            minWidth: '222px'
        }
    },
    flexHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    defaultGridStyle: {
        '& .RaDatagrid-headerCell': {
            padding: "10px 5px 10px 5px",
            fontSize: "0.85rem",
            lineHeight: 1,
            textAlign: 'center'
        },
        '& .RaDatagrid-headerRow': {
            borderLeftColor: "transparent",
            borderLeftWidth: 5,
            borderLeftStyle: "solid",
        },
        '& .RaDatagrid-row': {
            padding: "6px 8px 6px 8px",
        }
    },
    subGridDefaultStyle: {
        '& .RaDatagrid-headerCell': {
            fontSize: "0.9rem",
            textAlign: 'left'
        },
        '& .RaDatagrid-row': {
            padding: "unset",
        }
    },

    tabbedFormBoldHeader: {
        '& .Mui-selected.form-tab': {
            fontWeight: 600
        },
    }
};


