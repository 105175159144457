import {PublicFieldProps} from "ra-ui-materialui/src/field/types";
import * as React from 'react';
import {Button, useRecordContext} from "react-admin";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

interface Props extends PublicFieldProps {

}

// @ts-ignore
const DownloadButton: FC<Props> = ({label, source}) => {
    const record = useRecordContext();
    return record && record[source] ? (
        <a href={record[source]} target='_blank'>
            <Button
                label={label}>
                <CloudDownloadIcon/>
            </Button>
        </a>
    ) : null;
};

export default DownloadButton;

